/* Center the container within the page */
.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  min-height: 100vh; /* Take full viewport height */
  padding: 1rem; /* Add padding for small devices */
  max-width: 500px!important;
  margin: auto;
}

/* Adjust the spacing between rows */
.row {
  border-bottom: 1px solid #dee2e6; /* Subtle border for better visual separation */
  border-radius: 8px; /* Rounded corners for rows */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 1rem; /* Add padding inside rows */
  margin-bottom: 1rem; /* Space between rows */
  transition: transform 0.3s ease; /* Smooth hover effect */
 /* min-width: 100%; */
 max-width: 1000px!important;
 width: 100%;
 backdrop-filter: blur(10px)!important;
 -webkit-backdrop-filter: blur(10px)!important;
 margin: auto;
}

/* Add hover effect for desktop */
.row:hover {
  transform: translateY(-5px); /* Slight lift on hover */
}

/* Image styles */
.img-fluid {
  max-width: 100%; /* Keep images responsive */
  height: auto;
  border-radius: 50%; 
  border: 2px solid #e9ecef; /* Border around images */
}

/* Title styles */
.fs-5 {
  text-align: center; /* Center title text */
  color: #343a40; /* Dark text color */
}

/* Description styles */
.text-muted {
  text-align: justify; /* Align text for readability */
  line-height: 1.5; /* Increase line spacing */
  color: #6c757d; /* Muted text color */
}

/* Responsive layout adjustments for smaller screens */
@media (max-width: 576px) {
  .row {
    flex-direction: column; /* Stack elements on top of each other */
    align-items: center; /* Center all elements */
  }

  .col-12 {
    margin-bottom: 1rem; /* Add spacing between stacked columns */
  }
  h3 {
    text-align: center;
  }
}

/* Responsive adjustments for larger screens */
@media (min-width: 768px) {
  .row {
    flex-wrap: nowrap; /* Prevent wrapping for rows */
    align-items: center;
  }
}


h1 {
  margin: auto;
  text-align: center;
  backdrop-filter: blur(10px)!important;
-webkit-backdrop-filter: blur(10px)!important;
max-width: 300px;
/* background: rgba(0, 208, 208, 0.515); */
border-radius: 20px;
/* box-shadow: 0 0 20px white; */
}

