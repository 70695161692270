/*
  Theme Name: Bigtech - ICO & Crypto Landing Page Template
  Support: themegenix@gmail.com
  Description: Bigtech - ICO & Crypto Landing Page Template.
  Version: 1.0
*/

/* CSS Index
-----------------------------------

1. Theme default css
2. Header
3. Mobile-menu
4. Banner
5. Breadcrumb
6. Countdown
7. About
8. Choose
9. Chart
10. Counter
11. RoadMap
12. Document
13. Team
14. Download
15. Faq
16. Blog
17. Pagination
18. Contact
19. Newsletter
20. Footer
21. Preloader

*/

/* 1. Theme default css */

/* iframe {


height: 400px;
width: 100%;
width:100%;



} */

/* 
.swiper {
  width: calc(100% - 300px)!important;
}

.swiper-slide {
  width: calc((100% - 300px) / 3)!important;
}

@media (min-width: 768px) {
  .swiper-slide {
    width: calc((100% - 300px) / 4)!important;
  }
} */

@font-face {
  font-family: Dinosaur;
src: url(./Dinosaur.ttf);
}




/* .time-count {
  text-shadow: 0 3px black!important;
} */
.contentw {
  max-width: 900px;
  
}

.border-end {
  border-right: none!important;
  /* box-shadow: inset 0 0 10px #209b81; */
  /* box-shadow: 5px 0 5px -20px #209b81; */
}

.contentw b{
  color: #ffffff;
}


.sidebar-container , .contentwhole {
background: linear-gradient(black 45%, #061e18);
}
.sidebar-container span{

  float: inline-end;
  /* background: linear-gradient(#0f4b3e, black); */
  padding: 5px;
  border-radius: 10px;
  font-family: Dinosaur;
  font-weight: 100!important;
  color: #209b81;
}
.overflowhidden {
  overflow-x: hidden!important;
}



.contentw h4 {
  font-family: Dinosaur;
  letter-spacing: 1px;
  font-weight: lighter;
}

.contentw p {
  margin-bottom: 25px!important;
}
 
.contentmain h4 {
  text-align: left!important;
}

.nav-link {
  color: #209b81;
}

.nav-link:active {

}

.nav-link:hover {
  color:#209b81!important;
  /* font-weight: bold!important; */
}

.lpflogo {
  max-height: 100px;

}


.lastrm {
  /* margin: auto; */
  /* flex-direction: row; */
}


.sidebar-container {
  height: auto; /* Default for mobile */
}

@media (min-width: 768px) {
  .sidebar-container {
    height: 100vh; /* Apply full height only on tablets and larger screens */
  }
}

.interactive:hover {
color: #20b2a8!important;
/* text-shadow: 0 2px black; */
}

.direction {
  flex-direction: column!important;
}

.mainnetimg {
  border-radius: 20px;
}

.mainnet {
  -webkit-text-stroke: 0.5px #20b2a8;
 
  
}

@media (max-width: 992px) {

  .sidebar-container {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-right: none!important;
  }
.spacersm {
  height: 50px
}

}
.dividersm {
  height: 30px;
}

.blackfilter {
  filter: drop-shadow(0 0 20px black)!important;
}

.presalelive {
  font-size: 50px;
  color: white;
}


.pigroup {
  /* background: black; */
  border-radius: 15px;
  /* margin-top: 5px; */
  margin-bottom: 10px;
}

.pigroup img {
  height: 50px;
}

.pigroup button {
 padding: 10px;
border-radius: 15px;
background: transparent;
border: none;
}

.problemimg {
  border-radius: 20px;
 width: 100%;
 /* max-height: 500px; */
 /* border: 3px solid #ffffff; */
 border-bottom-right-radius: 0;
 border-bottom-left-radius: 0;
 margin:auto;
 max-height: 100%;
 /* aspect-ratio: 16 / 9!important; */
 /* height: 500px; */

}

.divider {
  height: 50px;
}

.aboutbr {
  border-radius: 20px;
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}
/* 
[data-reactroot] 
        {height: 100% !important; } */

a:hover {
  color: white!important;
}

/* iframe[src*=youtube] {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  padding-bottom: 10px;
} */

.row2 {
  /* margin-top: 175px; */
  /* margin-top: calc(100px + 5vw)!important; */
}

/* .iframewrap {
width: 100%;
aspect-ratio: 16 / 9!important;
margin: auto;
overflow: hidden;
} */


/* .video-container {
  position: relative;

}

.video-container iframe {
  position: absolute;
  
  width: 100%;
  height: 100%;
} */

/* .yt-container {
  position: relative;
  padding-top: 56.25%;
} */


  /* .yt-embed {
    aspect-ratio: 16 / 9!important;
    width: 100%;
  } */
  @media screen and (max-width:549px) {
  .presalebtn {
    margin: auto!important;
    margin-right: 0px!important;
  }
}

  @media screen and (max-width:992px) {

   

    .custom-countdown-two span {
      min-width: 50px!important;
    }

    .countdown-area-two .countdown-wrap2 {
padding: 15px 10px 15px!important;
/* max-height: 400px; */
    }

    .buynowbtn {
      /* width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 200px!important;
      margin: auto!important; */
      width: 100%;
      /* margin-left: 10%; */
      background: transparent;
      border: none;
      margin: auto;
    }
    
    
    .buynowbtn a {
      
      text-shadow: 0 0 4px black;
    /* font-size: calc(20px + 1.2vw); */
    /* font-size: 20px; */
    /* font-size: 50px; */
    /* font-size: calc(10px + 1.1vw);
    padding: calc(5px + .5vw); */
    padding: 10px;
    /* font-family: ethno; */
    /* color: rgb(0, 55, 255); */
    color: rgb(255, 255, 255);
    line-height: calc(14px + 0.5vw)!important;
    font-size: calc(14px + 0.5vw)!important;
    font-family: ethno;
    font-weight: lighter;
    font-style: italic;
    
    }
    
    .mintnftbtn {
      width: 100%;
      /* margin-right: 10%; */
      margin: auto;
      background: transparent;
      border: none;
      margin: auto;
     
    }

    .mintnftbtn a {
      padding: 5px;
      /* font-family: ethno; */
      /* color: rgb(0, 55, 255); */
      color: rgb(255, 255, 255);
      line-height: calc(14px + 0.5vw)!important;
      text-shadow: 0 0 4px black;
      font-size: calc(14px + 0.5vw)!important;
      font-family: ethno;
      font-weight: lighter;
     font-style: italic;
    }
    


    .pigroup img {
      height: 30px;
      width: 30px;
    }
    

    .countdown-area-two .countdown-wrap {
      /* background: radial-gradient( #20b2a8af, black)!important; */
      /* border: 3px solid #20B2A9; */
      /* box-shadow: 0px 34px 35px rgba(160, 171, 191, 0.21); */
       backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      border-radius: 30px;
      position: relative;
      text-align: center;
      /* padding: 55px 50px 60px; */
      /* border: 4px solid #20B2A9;
      box-shadow:  0 0 2px #20b2a8; */
      margin-bottom:5px;
      height: 350px!important;
      /* width: 300px!important; */
     
    
    }

    .countdown-area-two .countdown-wrap2 {
      background: radial-gradient( #20b2a8af, black)!important;
      
      /* border: 3px solid #20B2A9; */
      /* box-shadow: 0px 34px 35px rgba(160, 171, 191, 0.21); */
       backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      border-radius: 30px;
      position: relative;
      text-align: center;
      /* padding: 55px 50px 60px; */
      /* border: 4px solid #20B2A9;
      box-shadow:  0 0 2px #20b2a8; */
      margin-bottom:20px;
      /* height: 350px!important; */
      /* width: 500px!important; */
      position: relative;
      
    
    }

    .countdown-area-two .countdown-wrap::before {
      /* width: 375px!important; */
    
    }

    .problemimg {
      /* border-radius: 20px; */
      /* max-width: 300px; */
      width: 100%!important;
     
     
    }


    .lgimg {
      /* margin-top: 50px!important; */
    }
    .banner-bg2 {
      /* margin-top: calc(15vh + 20px); */
      min-height:0!important;
    }

    .about-vid {
      /* height: 300px!important; */
      /* width: 100%!important;
      margin:auto;
      height: 100%; */
    }

    .maintitle {
      /* font-size: calc(14px + 1vw)!important; */
      text-align: center;
      width: 100%;
   
    }

  
    
    .presalebtn a {
  
      /* color: rgb(0, 0, 0); */
      text-shadow: 0 0 4px black;
      /* font-size: calc(20px + 1.2vw); */
      /* font-size: 16px!important; */
      /* font-size: calc(10px + 1.1vw);
      padding: calc(5px + .5vw); */
      padding: 5px;
      /* font-size: 16px; */
      /* font-size: 16px!important; */
      /* font-family: ethno; */
      /* color: rgb(0, 55, 255); */
      color: rgb(255, 255, 255);
      /* line-height: calc(16px + 3vw); */
     
    }
  
    .mint a {
      /* color: rgb(0, 0, 0); */
      /* text-shadow: 0 0 7px black; */
      /* font-size: calc(7px + 1.2vw); */
      /* font-size: 16px!important; */
      /* font-size: 16px; */
      /* font-size: 16px!important; */
      /* padding: 5px!important; */
      /* padding: calc(3px + .5vw); */
      padding: 5px;
      /* font-family: ethno; */
      /* color: rgb(0, 55, 255); */
      color: rgb(255, 255, 255);
      /* line-height: calc(16px + 3vw); */
      text-shadow: 0 0 4px black;
    
     
    }
  }




#hero-vid {
  /* max-width: 100%; */
  min-height: 100vh;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -999!important;
  -webkit-transform: translateZ(0);
  opacity: 0!important;
  padding-bottom: 150px;

 
 

  }


  /* .cdmain {
    height: 500px!important;
  } */
  #hero-vid2 {
    /* max-width: 100%; */
    min-height: 100vh;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: fixed;
    /* object-position: center!important; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -998!important;
    -webkit-transform: translateZ(0);
    opacity: 0.5!important;

 
   
  
    }
  
.bg-3 {
  background: url(../img/images/infwebbg.jpg);
  min-height: 100vh;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
  background-position: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -999!important;
  -webkit-transform: translateZ(0);
  opacity: 1!important;
  /* padding-bottom: 150px; */
  /* background: linear-gradient(black, rgba(0, 0, 0, 0.194)); */
}

  .vidwrap {
    
   min-height: 100vh;
    width: 100%;
    /* max-width:100vw; */
    position: absolute;
   
  }
  

  .vidwrap-info {
    
    
     width: 100%;
     /* max-width:100vw; */
     
    
   }

   #hero-vid-info {
    /* max-width: 100%; */
    /* min-height: 100vh; */
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* position: fixed; */
    /* object-position: center!important; */
    margin:auto;
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
    z-index: -998!important;
    /* -webkit-transform: translateZ(0); */
   

 
   
  
    }


  .about-vid {
    /* height: 300px!important; */
  
    margin:auto;
    /* aspect-ratio: 16 / 9!important; */
    width: 100%!important;
    /* max-width: 800px!important; */
   
  }

.iwrap{
text-align: center;
  width: 100%;
 


  /* width: 100%; */
}
/* .marginb .row {
  height: 50vh;
} */


.maintitle {
  font-size: calc(20px + 1.5vw);
  text-align: center;
  width: 100%;
  margin-top: 30px;
  font-family: ethno;
  font-weight: lighter;
  text-shadow: 0 4px rgb(0, 0, 0);
  color: #f2f2f2;
  /* -webkit-text-stroke: 1px black; */
  font-style: italic;
}

.articlewrap {
  /* background: url(../img/images/dragonr2.gif); */
    background: url(../img/images/bbblurry3.svg);
  background-position: center;
  background-repeat: no-repeat;

}


.articlewrap .swiper-button-prev {
background: wheat;
padding:20px!important; 
display: none;
}

.articlewrap .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;

display: none;
}

.card-text {
  font-size: 20px;
  line-height: 30px;
  color: white!important;
  text-align: center;
padding: 5px;
 
}

.wrapper{
  max-width: 100%;
  /* max-width: 1400px; */
  /* background-image:  url(../img/images/Sprinkle\ \(1\).svg); */
  /* margin: auto; */
  /* border-radius: 30px;
  border: 3px solid #20B2A9; */
  
}

.card {
  background-color: #20b2a818;
  /* border: 2px solid #20b2a8a9; */
  border: 2px solid #20b2a8;
  border-radius: 20px;
  margin-bottom: 100px;
 width: 275px!important;
  box-shadow: 0 0 20px #20b2a858;
  /* margin-top: 20px; */
  backdrop-filter: blur(35px)!important;
  -webkit-backdrop-filter: blur(35px)!important;
 
 
}


.c2c {
  font-size: calc(10px + 0.2vw)!important;
  /* color: #fdfdfd!important; */
}
.spacer {
  height: 40px;
}

.card .btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.brandimg {
  padding: 10px;
  width: 200px!important;
  margin: auto;
  filter: drop-shadow(0 0 20px rgb(0, 234, 255));
  
}

.brandimg2 {
  /* padding: 10px; */
  /* width: 200px!important; */
  /* margin: auto; */
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  filter: drop-shadow(0 0 20px rgb(0, 234, 255));

  
}

.card {
  height: 480px!important;
  width: 280px!important;
}

/* .swiperheight {
  height: 500px;
  width: 100%;
} */

.firstinfo {
  margin-bottom: 20px!important;
}

.infostyle, .firstinfo {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.infostyle p {
 color: #20b2a8!important;
 border: 2px solid rgba(255, 255, 255, 0.254);
 border-radius: 10px;
 font-size:  calc(12px + 0.6vw);
 padding: 3px;
 text-align: left;
 box-shadow: 0 3px rgb(0, 0, 0);

}

.infostyle  span {
  color :white;
  font-family: 'Courier New', Courier, monospace;
  font-size: calc(12px + 0.4vw);
}

.card img {
  border-radius: 20px;
  border-bottom-right-radius: 0;
border-bottom-left-radius: 0;
}

.card-img-top {
  height: 150px!important;
  
}

.articles {
  margin-bottom: 100px;
}


/* .ytsection {
  margin-top: 200px;
  margin-bottom: 100px;
} */

.ytimage {
  margin: auto;
width: 200px;
margin-bottom: 25px;
margin-top: 50px;
}

.articleh1 {
  text-align: center;
  margin: auto;
  width: 300px;
  /* margin-top: 250px; */
 
}

.carousel-caption p {
  color: white!important;
}

/* .carousel-item  {
  min-height: 100vh;
} */

/* .carousel-item img  {
  min-height: 100%;
} */


.aboutp {
  /* background: linear-gradient( #000000, #000000ba,#000000),url(../img/images/gear.gif)!important; */
  /* background-size: cover!important;
  background-repeat: no-repeat!important; */



}

.blur {
  backdrop-filter: blur(50px)!important;
-webkit-backdrop-filter: blur(50px)!important;


}

.aboutp2 {
  font-size:  calc(20px + 2vw)!important;
  /* background: url(../img/images/bbblurry2.svg); */
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1200px;
 
} 




.partnerbg {
  /* background: linear-gradient( #000000, #00000013,#000000),url(../img/images/infwebbg.jpg)!important; */
  background-size: cover!important;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
}
/* .presalebtn {
    box-shadow: 0 0 15px rgb(0, 140, 255);
} */

/* .wrapper-vid {
  position: relative;
  width: 100%
}

.wrapper-vid::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: radial-gradient(
    circle at 36% 48%,
    #000000,
    rgba(11, 39, 65, 0.32) 87%,
    rgba(0, 0, 0, 0)
  );
}


.wrapper-vid video {
  width: 100%;
  aspect-ratio: 4/3;
} */

.highlight {
/* color: rgb(255, 255, 255)!important; */
/* background: 
linear-gradient(to right, #00f2e2, rgb(39, 201, 255), rgb(11, 157, 255), #00ffee); */
background: 
linear-gradient(to right,#00ffee, #00ffee79)!important;
/* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0) ); */
/* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0), rgb(0, 0, 0),rgb(0, 112, 95)); */

/* linear-gradient(to right, rgba(242, 0, 255, 0.4), #009dff79); */

/* color: white!important; */
color: rgb(0, 0, 0)!important;
/* text-shadow: 0 2px black; */
font-weight: bolder;

}

.centerpiece {
  /* background: url(../img/images/infvid3.gif); */
  /* background: linear-gradient(black,#005e685f, black) ; */
  /* background: radial-gradient(circle at center, rgb(0, 0, 0) 0, #005e685f, rgb(0, 0, 0) 100%); */
  /* background: radial-gradient(circle at center, rgb(0, 0, 0) 0, #00aeab04, rgb(0, 0, 0) 100%); */
  /* background:  linear-gradient(rgba(0, 0, 0, 0.408),#005e685f, black), url(../../assets/img/images/infpresale.jpg); */
  /* background-size: 100% ; */
  background-repeat: no-repeat;
  padding: 5px;
  margin-top: 30px!important;
  /* margin-top: 14vh!important; */
  /* margin-top: 200px!important; */
display: inline-block!important;
 


/* background-size: 50%; */
/* padding: 50px; */
border-radius: 10px;
/* box-shadow:  0 0 40px #00f7ff5e; */
/* width: 300px; */
margin: auto;
/* background-size: contain;
background-repeat: no-repeat;
background-position: center; */



}

.highlight2 {
  /* color: rgb(255, 255, 255)!important; */
  /* background:  linear-gradient(to right, #00f2e26c, rgba(39, 201, 255, 0.446), rgba(11, 157, 255, 0.422), #00ffee7a); */
  /* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0), rgb(0, 0, 0),rgb(0, 112, 95)); */
  color: rgb(255, 255, 255)!important;
  text-shadow: 0 0 4px black;
  
  }


  .highlightfooter {
    /* color: rgb(255, 255, 255)!important; */
    /* background: 
    linear-gradient(to right, #00f2e2, rgb(39, 201, 255), rgb(11, 157, 255), #00ffee); */
    /* background: 
    linear-gradient(to right, rgba(11, 157, 255, 0.4), #00ffee79)!important; */
    /* background: -webkit-linear-gradient(#014536, #00c4ff); */
    /* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0) ); */
    /* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0), rgb(0, 0, 0),rgb(0, 112, 95)); */
    
    /* linear-gradient(to right, rgba(242, 0, 255, 0.4), #009dff79); */
    
    /* color: white!important; */
    color: rgb(255, 255, 255)!important;
    text-shadow: 0 0 4px black;
    width: 100px;
    padding:2px;
    border-radius: 10px;
    text-align: center;
    border: 2px solid #20B2A9;
    
    }

    .highlightfooter2 {
      /* color: rgb(255, 255, 255)!important; */
      /* background: 
      linear-gradient(to right, #00f2e2, rgb(39, 201, 255), rgb(11, 157, 255), #00ffee); */
      /* background: 
      linear-gradient(to right, #00f2e26c, rgba(39, 201, 255, 0.446), rgba(11, 157, 255, 0.422), #00ffee7a); */
      /* background: -webkit-linear-gradient(#014536, #00c4ff); */
      /* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0) ); */
      /* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0), rgb(0, 0, 0),rgb(0, 112, 95)); */
      
      /* linear-gradient(to right, rgba(242, 0, 255, 0.4), #009dff79); */
      
      /* color: white!important; */
      color: rgb(255, 255, 255)!important;
      text-shadow: 0 0 4px black;
      width: 100px;
      padding:2px;
      border-radius: 10px;
      text-align: center;
      border: 2px solid #20B2A9;
      
      }
.presalebtn  {
  border-radius: 15px;
  /* background: -webkit-linear-gradient(#00d9ed 30%, #00c3ff, rgb(0, 55, 255)); */
  /* box-shadow: 0 0 20px rgb(0, 140, 255); */
  box-shadow: 0 0 20px #00f7ff5e;
  /* border: none; */
  /* margin-top: 40px; */
  /* border: 3px solid #00c3ff; */
  /* background: linear-gradient(black ,black) padding-box,
linear-gradient(to right, rgb(0, 60, 255), rgb(0, 225, 237), rgb(0, 119, 255)) border-box; */
/* background: linear-gradient(rgb(0, 212, 181) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #00f2e2, rgb(1, 217, 255), rgb(11, 243, 255), #05f2ff) border-box; */
/* background: linear-gradient(rgb(0, 0, 0) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #00f295, rgb(39, 255, 226), rgb(66, 232, 244), #00eeff) border-box; */
/* background: linear-gradient(rgb(0, 0, 0) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #009159, rgb(30, 210, 183), rgb(40, 189, 200), #009159) border-box; */
/* 
background: linear-gradient(rgb(0, 0, 0) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #009159, rgb(23, 171, 197), rgb(40, 189, 200), #009159) border-box;
border: 2px solid transparent; */
backdrop-filter: blur(20px)!important;
-webkit-backdrop-filter: blur(20px)!important;
/* background:  #00f7ff60; */
background:  #00f7ff9f;
/* display: inline-block!important; */
/* border: 2px solid rgba(255, 255, 255, 0.441); */
/* font-family: ethno; */
border: 3px solid rgba(0, 242, 255, 0.55);
}


.mint  {
  border-radius: 15px;
  /* background: -webkit-linear-gradient(#00d9ed 30%, #00c3ff, rgb(0, 55, 255)); */
  /* box-shadow: 0 0 20px rgb(0, 140, 255); */
  /* border: none; */
  margin-top: 30px;
  /* border: 3px solid #00c3ff; */
  /* background: linear-gradient(black ,black) padding-box,
linear-gradient(to right, rgb(0, 60, 255), rgb(0, 225, 237), rgb(0, 119, 255)) border-box; */
/* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0) ) padding-box,
linear-gradient(to right, #00f295, rgb(28, 188, 166), rgb(66, 232, 244), #00eeff) border-box; */
/* background: linear-gradient(rgb(0, 0, 0) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #009159, rgb(30, 210, 183), rgb(40, 189, 200), #009159) border-box;
border: 2px solid transparent; */
backdrop-filter: blur(20px)!important;
-webkit-backdrop-filter: blur(20px)!important;
/* background:  #00f7ff60; */
background: transparent;
/* box-shadow: 0 0 20px #00f7ff; */
/* border: 3px solid rgb(0, 157, 255);
background:rgb(0, 212, 181); */
/* border: 2px solid rgba(255, 255, 255, 0.441); */
border: 3px solid #00f7ff8b;
/* display: inline-block!important; */
/* font-family: ethno; */
/* font-weight: lighter; */
}

.mint a {
  /* color: rgb(0, 0, 0); */
  /* text-shadow: 0 0 7px black; */
  /* font-size: calc(7px + 1.2vw); */
  /* font-size: 18px; */
  /* font-size: 40px; */
  /* padding: 5px!important; */
  /* padding: calc(3px + .5vw); */
  padding: 10px;
  /* font-family: ethno; */
  /* color: rgb(0, 55, 255); */
  color: rgb(255, 255, 255);
  /* line-height: calc(16px + 3vw); */
  text-shadow: 0 0 4px black;
  font-size: calc(20px + 1vw);
  font-family: ethno;
  font-weight: lighter;
 font-style: italic;
}

iframe {
 /* text-align: center!important;
 margin: auto!important;
 max-width: 500px;
 width: 100%; */
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide .card-img-top {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */


.presalebtn a {
  /* color: rgb(0, 0, 0); */
  text-shadow: 0 0 4px black;
  /* font-size: calc(20px + 1.2vw); */
  /* font-size: 20px; */
  /* font-size: 50px; */
  /* font-size: calc(10px + 1.1vw);
  padding: calc(5px + .5vw); */
  padding: 10px;
  /* font-family: ethno; */
  /* color: rgb(0, 55, 255); */
  color: rgb(255, 255, 255);
  /* line-height: calc(16px + 3vw); */
  font-size: calc(20px + 1vw);
  font-family: ethno;
  font-weight: lighter;
 font-style: italic;
}

.presalebtn a:hover, .mint a:hover {
  color: white;
}

.whitetxt{
  color: white!important;
}
#about video {
  width: 100%!important;
  border-radius: 10px;
  box-shadow: 0 0px 350px rgba(0, 238, 255, 0.411);
  border: 3px solid rgba(0, 238, 255, 0.483);
    /* box-shadow: 0 0px 350px rgb(0, 0, 0); */
    margin-bottom: 5px;
    
   

}

.inlinebtn {
  justify-content: center!important;
  
}

@font-face {
  font-family: ethno;
  src: url(./ethnocentric.otf);
}

/* .videoTag {
  height: 100%;
width: 100%;
float: left;
top: 0;
padding: none;
position: fixed;
object-fit: cover;
z-index: -400;
} */

.banner-area {
  /* width: 100%; */
  /* height: 100vh; */
  /* height: calc(100vh -90px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
  /* margin-top: 15vh; */
}

.vidwrap {
  width: 100%;
  height: 100vh;

  transition: 0.5s;
}

.tokenomics p {
backdrop-filter: blur(50px);
border-radius: 15px!important;
padding: 30px;
}
.tokenomics {
  background: url(../img/images/infyt.jpg);
  background-size: cover;
  background-position: center;
}


.video-bg {
  width: 100%;
 height: 100vh;
  /* position: absolute; */
  top: 0;
  left: 0;
  
  z-index: -990!important;
  object-fit: cover;
  position: absolute;
}

/* 
video {
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  bottom: 0;
} */
.text-grad {
/* background: -webkit-linear-gradient(#00ffc7, #00c4ff); */
background: -webkit-linear-gradient(#20B2A9, #37fff2,#20B2A9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-grad2 {
  background: -webkit-linear-gradient(#005e68, #00c4ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
  }

  .text-grad3 {
    background: -webkit-linear-gradient(#003b8d, #00c4ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
  

.lgimg {
  /* padding: 20px; */
  filter:drop-shadow(0 0 30px black);
  padding-left: 15px;
  padding-right: 15px;
  /* margin-top: 30px; */
}

.lgimg2 {
  /* padding: 20px; */
  filter:drop-shadow(0 0 30px black);
  border-radius: 20px;
}

.lgimg3 {
  /* padding: 20px; */
  filter:drop-shadow(0 0 30px black);
  border-radius: 20px;
  background: url(../../assets/img/images/infpresale.jpg);
  background-size: cover;
  width: 100%;
  height: 300px;
}



.mainimg {
  width: calc(250px + 7vw);
  max-width: 500px!important;
  filter: drop-shadow(2px 4px 6px black);
}

.logoimg {
  width: calc(50px + 1vw)!important;
  /* filter:drop-shadow(0 0 5px black) */
 
}

.about h2, h4 {
  text-align: center!important;
}

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #a4b4c3;
  font-style: normal;
  /* background-color: #020202; */
  /* background-color: #003a78; */
  /* background-color: #00142f; */
  /* background-color: #000f23; */
  /* background-color: #01050a; */
  /* background: -webkit-linear-gradient(#005e68, #00c4ff); */
  /* background: -webkit-linear-gradient(#004e66 5%, #000d0f, #000d0f); */
  /* background: -webkit-linear-gradient(#004e66 2%, #000d0f, #000d0f); */
  /* background: -webkit-linear-gradient(#004e66 2%,#000d0f, #000d0f, #000d0f); */
  /* background: -webkit-linear-gradient(#004e66 1%,#000d0f, #000d0f, #000d0f, #000d0f); */
    /* background: linear-gradient( #000000, #000000c2,#000000), url(../../assets/img/images/infvid3.gif)!important; */
    /* background: linear-gradient( #000000, #000000c2,#000000), url(../../assets/img/images/infwebbg.jpg)!important; */

  background-color: #000;
/* 
min-height: 100vh!important;
height: 100%; */
  line-height: 1.75;
  /* overflow-x: hidden; */
  /* height: calc(var(--vh, 1vh) * 100); */


  /*added for white screen glitch on mobile*/

  /* overflow-y: scroll!important;

-webkit-overflow-scrolling: touch!important; */

  overflow-y: scroll;
height: 100%;

}
body.white-background,
.white-background 
{
  background-color: #ffffff;
}
img,
.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
a,
button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
a:focus,
.btn:focus,
button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  color: #20B2A9;
  text-decoration: none;
}
a,
button {
  color: #20B2A9;
  outline: medium none;
  text-decoration: none;
}
.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-top: 0px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 40px;
}
/* h2 {
  font-size: 35px;
} */
h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
  color: #727885;
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid #eceff8;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}
label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
*::-moz-selection {
  background: #20B2A9;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #20B2A9;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #20B2A9;
  color: #fff;
  text-shadow: none;
}
*::-moz-placeholder {
  color: #20B2A9;
  font-size: 14px;
  opacity: 1;
}
*::placeholder {
  color: #555555;
  font-size: 14px;
  opacity: 1;
}
.theme-overlay {
  position: relative;
}
.theme-overlay::before {
  background: #1696e7 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.separator {
  border-top: 1px solid #f2f2f2;
}

/* Bootstrap 5 */
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  --bs-gutter-x: 30px;
}
.row.g-0 {
  --bs-gutter-x: 0;
}
.gutter-y-30 {
  --bs-gutter-y: 30px;
}

/* button style */
.btn {
  user-select: none;
  -moz-user-select: none;
  background: #000000;
  border-radius: 70px;
  border: 2px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-bottom: 0;
  padding: 25px 50px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s 0s;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
  background-clip: padding-box;
  position: relative;
}
.btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: -2px;
  /* background: linear-gradient(
    90deg,
    #20B2A9 0%,
    rgb(0, 0, 0) 49%,
    #20B2A9 100%
  ); */
  border-radius: 70px;
  transition: all 0.4s ease 0s;
  z-index: -1;
  border: 3px solid #20B2A9;
}
.btn:hover {
  border-color: #00bbff;
  background-color: transparent;
}
.btn.btn-two {
  background: #564dca;
  border-radius: 5px;
  padding: 25px 42px;
  border: none;
}
.btn.btn-two::after {
  display: none;
}
.btn.btn-two:hover {
  color: #fff;
  background: #20B2A9;
} 
.breadcrumb > .active {
  color: #20B2A9;
}

/* scrollUp */
.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  bottom: 105%;
  right: 50px;
  font-size: 16px;
  border-radius: 50%;
  z-index: 99;
  color: #3d3d3d;
  text-align: center;
  cursor: pointer;
  background: #dcbc95;
  transition: 1s ease;
  border: none;
}
.scroll-top.open {
  bottom: 30px;
}
.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.scroll-top:hover {
  background: #a3a3a3;
}

/* 2. Header */
.custom-container {
  max-width: 1630px;
}
.custom-container-two {
  max-width: 1700px;
}
.custom-container-three {
  max-width: 1520px;
}
.custom-container-four {
  max-width: 1200px;
}
.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}
.menu-area {
  /* border-bottom: 1px solid rgb(255 255 255 / 10%); */
  border-bottom: 1px solid rgba(0, 160, 184, 0.165);
  /* box-shadow: 0 0 80px rgba(0, 160, 184, 0.485); */


}
.menu-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.navbar-wrap {
  display: flex;
  flex-grow: 1;
}
.navbar-wrap ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 140px;
}
.navbar-wrap > ul > li {
  display: block;
  position: relative;
  margin-right: 10px;
}
.navbar-wrap > ul > li > a {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  padding: 45px 0;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  letter-spacing: 0.1px;
}
.navbar-wrap > ul > li:last-child {
  margin-right: 0;
}
.navbar-wrap > ul > li > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #20B2A9;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.navbar-wrap > ul > li.active > a::before,
.navbar-wrap > ul > li > a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li:hover > a {
  color: #fff;
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
  display: none;
}
.header-action > ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.header-action > ul > li {
  position: relative;
  margin-left: 65px;
  padding-left: 65px;
}
.header-action > ul > li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.header-action > ul > li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 28px;
  background: #ffffff;
  opacity: 0.2;
}
.header-action > ul > li:first-child::before {
  display: none;
}
.header-lang {
  position: relative;
}
.header-lang .selected-lang {
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: 1px;
}
.header-lang .selected-lang::after {
  content: "\f107";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: #20B2A9;
  margin-left: 10px;
}
.header-lang .lang-list {
  position: absolute;
  left: -10px;
  top: calc(100% + 25px);
  background: #0b1d33;
  z-index: 3;
  padding: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 30px 70px 0px rgba(40, 44, 49, 0.15);
  min-width: 100px;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  transform: perspective(400px) rotateX(-45deg);
  transition: all 0.3s ease-out 0s;
}
.header-lang:hover .lang-list {
  visibility: visible;
  opacity: 1;
  transform: perspective(400px) rotateX(0deg);
}
.header-lang .lang-list li {
  margin-bottom: 5px;
  line-height: 1;
}
.header-lang .lang-list li:last-child {
  margin-bottom: 0;
}
.header-lang .lang-list li a {
  display: block;
  font-size: 16px;
  color: #fff;
  padding: 5px 15px 5px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.header-lang .lang-list li a:hover {
  color: #20B2A9;
}
.header-btn .btn {
  font-size: 14px;
  padding: 20px 45px;
}
.navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: #0b1d33;
  margin: 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  
}
.navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
  
}
.navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  line-height: 1.4;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  font-size: 14px;
}
.navbar-wrap ul li .sub-menu li a:hover,
.navbar-wrap ul li .sub-menu li.active a {
  color: #20B2A9;
}
.navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #000000bb;
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
    backdrop-filter: blur(50px)!important;
  -webkit-backdrop-filter: blur(50px)!important;
}
.sticky-menu .navbar-wrap > ul > li > a {
  padding: 45px 0;
}
#header-fixed-height.active-height {
  display: block;
  height: 105px;
  
}

/* header-two */
.menu-area.menu-style-two {
  border-bottom: none;
  padding: 30px 0;
}
.menu-style-two .navbar-wrap > ul > li > a {
  padding: 40px 0;
}
.menu-style-two .navbar-wrap > ul > li > a::before {
  display: none;
}
.menu-style-two .header-btn .btn {
  background: #20B2A9;
  border-radius: 5px;
}
.menu-style-two .header-btn .btn::after {
  display: none;
}
.menu-style-two .header-btn .btn:hover {
  background: #564dca;
  border-color: #564dca;
  color: #fff;
}
.menu-area.menu-style-two.sticky-menu {
  padding: 0 0;
  
}
.menu-style-two .navbar-wrap > ul > li.active > a,
.menu-style-two .navbar-wrap > ul > li:hover > a {
  color: #20B2A9;
}

/* 3. Mobile-menu */
.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: #fff;
  margin-right: 30px;
  top: 15px;
}
.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  min-height: 100vh!important;
  height: 100%;
  opacity: 0;
  visibility: visible;
  z-index: 999;
  border-radius: 0px;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
  background: #0000001d;
  /* backdrop-filter: blur(50px)!important;
  -webkit-backdrop-filter: blur(50px)!important; */
}
.mobile-menu .navbar-collapse {
  display: block !important;
}
.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.mobile-menu-visible {
  /* overflow: hidden; */
  
  
}
.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  /* backdrop-filter: blur(50px)!important;
  -webkit-backdrop-filter: blur(50px)!important; */
  transform: translateX(0%);

}
.mobile-menu .navigation li.current > a:before {
  height: 100%;
  /* backdrop-filter: blur(50px)!important;
  -webkit-backdrop-filter: blur(50px)!important; */
}
.menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* z-index: 10; */
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
    /* border-left: 3px solid #20B2A9; */
  /* backdrop-filter: blur(50px)!important;
  -webkit-backdrop-filter: blur(50px)!important; */
  /* background: #0b1d3337; */
  /* backdrop-filter: blur(80px)!important;
  -webkit-backdrop-filter: blur(80px)!important; */
}
.mobile-menu-visible .menu-backdrop {
  opacity: 0.8;
  visibility: visible;
  /* backdrop-filter: blur(50px)!important;
  -webkit-backdrop-filter: blur(50px)!important; */

}
.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  background: #000000;
  

  padding: 0px 0px;
  z-index: 999;
  box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
      /* box-shadow: 0 10px -15px black;  */
      /* backdrop-filter: blur(50px)!important;
      -webkit-backdrop-filter: blur(50px)!important; */
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  
  z-index: 2;
}
.mobile-menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: #20B2A9;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  /* backdrop-filter: blur(50px)!important;
  -webkit-backdrop-filter: blur(50px)!important; */
  /* box-shadow: 0 0 70px #005e68;
  border-radius: 20px; */
  /* border-left: 3px solid #20B2A9; */
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgb(255 255 255 / 6%);
}
.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgb(255 255 255 / 6%);
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgb(255 255 255 / 6%);
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 18px;
  padding: 10px 60px 10px 25px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
  letter-spacing: 0.08em;
}
.mobile-menu .navigation li ul li > a {
  font-size: 13px;
  margin-left: 20px;
  text-transform: capitalize;
}
.mobile-menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.mobile-menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.mobile-menu .navigation li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  background: #20B2A9;
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}
.mobile-menu .social-links ul {
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 10px;
}
.mobile-menu .social-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.mobile-menu .social-links li a:hover {
  border-color: #20B2A9;
  background: #20B2A9;
  color: #fff;
}
.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 30px;
  cursor: pointer;
  line-height: 1;
  color: #000;
  display: none;
  margin-top: 3px;
}

/* 4. Banner */
.banner-bg {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.718),rgba(0, 0, 0, 0.759)), url(../img/images/dragonr.gif); */
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 90px 0 0;
  overflow: hidden;
}

.banner-bg2 {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.718),rgba(0, 0, 0, 0.759)), url(../img/images/dragonr2.gif); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.745),rgba(0, 0, 0, 0.013)), url(../img/images/dragonr2.gif); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.745),rgba(0, 0, 0, 0.013), rgba(0, 0, 0, 0.52)), url(../img/images/dragonr2.gif); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.745),rgba(0, 0, 0, 0.001), rgba(0, 0, 0, 0.239)), url(../img/images/infwebbg.jpg); */
  /* background-image:  url(../img/images/infwebbg.jpg); */
  /* background-image:  url(../img/images/Sprinkle\ \(1\).svg); */
  /* backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.745),rgba(0, 0, 0, 0.001), rgba(0, 0, 0, 0.239)), url(../img/images/greentrans.png); */
/* padding-bottom: 200px!important; */
background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
  /* position: relative; */
  z-index: 1;
  /* padding: 90px 0 0; */
  overflow: hidden;
  padding-top: 20px;
  /* padding-bottom: 60px; */
  /* margin-bottom: 80px;
  margin-top: 0px; */
  /* min-height: 100vh; */
  /* height: calc(100vh -90px); */
  /* width: 100%; */
  /* width: 100vw; */
 

}
/* .banner-bg::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0.23deg,
    #030b15 5.68%,
    rgba(3, 11, 21, 0.42) 81.9%
  );
  z-index: -1;
} */


/* .valign {
  vertical-align: 300px!important;
} */

.vhheight {
  /* height: calc(100vh - 100px)!important;  */
 /* min-height: 100vh; */
}
.banner-shape-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.banner-shape-wrap img.img-one {
  top: 28%;
  left: -3%;
  animation: leftToRight 5s infinite linear;
}
.banner-shape-wrap img.img-two {
  left: auto;
  right: 8%;
  top: 20%;
}

/* .marginb {
  margin-bottom: calc(1rem + 1.4vw);

} */
.banner-shape-wrap img.img-three {
  left: auto;
  right: -2%;
  top: auto;
  bottom: 14%;
  animation: alltuchtopdown 3s infinite linear;
}
/* .banner-content img {
  margin-bottom: 25px;
} */
.banner-content .title {
  /* font-size: calc(14px + 2vw)!important; */
  /* margin-bottom: 30px; */
  line-height: 1;
  letter-spacing: -0.01em;
  /* font-size: calc(20px + 1.5vw); */
}
.banner-content .title span {
  color: #00eada;
  font-size: calc(17px + 0.7vw)!important;
  font-family: ethno;
  font-weight: 100;
  /* margin-bottom: 100px; */
}
.banner-progress-wrap ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 50px;
}
.banner-progress-wrap ul li {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #20B2A9;
  display: inline-block;
  position: relative;
  padding-bottom: 25px;
}
.banner-progress-wrap ul li::before {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 14px;
  background: #20B2A9;
}
.banner-progress-wrap ul li:nth-child(2) {
  color: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3) {
  color: #12d176;
}
.banner-progress-wrap ul li:nth-child(2)::before {
  background: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3)::before {
  background: #12d176;
}
.banner-progress-wrap {
  margin: 0 150px 70px;
}
.banner-progress-wrap .progress {
  height: 6px;
  background-color: #f2f2f2;
  border-radius: 0;
  overflow: inherit;
  margin-bottom: 25px;
  margin-top: 8px;
}
.banner-progress-wrap .progress .progress-bar {
  background-color: #20B2A9;
  position: relative;
  overflow: inherit;
}
.banner-progress-wrap .progress .progress-bar::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  z-index: 5;
  border: 6px solid #20B2A9;
}
.banner-progress-wrap .title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.banner-progress-wrap .title span {
  margin-left: auto;
}
.banner-countdown-wrap .title {
  font-size: 26px;
  margin-bottom: 15px;
  letter-spacing: -0.01em;
}
.banner-countdown-wrap .coming-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-countdown-wrap .coming-time .time-count {
  min-width: 162px;
  min-height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  margin: 20px 17.5px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  background: #0b1d33;
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  color: #a4b4c3;
  text-transform: capitalize;
}
.banner-countdown-wrap .coming-time .time-count span {
  font-size: 35px;
  font-weight: 600;
  color: #20B2A9;
  margin-bottom: 12px;
  display: block;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}
.banner-countdown-wrap .coming-time .time-count.hour span {
  color: #ff9700;
}
.banner-countdown-wrap .coming-time .time-count.min span {
  color: #ff1d45;
}
.banner-countdown-wrap .coming-time .time-count.sec span {
  color: #12d176;
}
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
@keyframes leftToRight {
  0% {
    transform: rotateX(0deg) translateX(0px);
  }
  50% {
    transform: rotateX(0deg) translateX(50px);
  }
  100% {
    transform: rotateX(0deg) translateX(0px);
  }
}

/* banner-two */
.banner-area-two {
  position: relative;
  padding: 225px 0 0;
  z-index: 1;
}
.banner-bg-two {
  background-image: url(../img/banner/banner_bg02.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 164px);
  z-index: -1;
}
.banner-bg-two::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -40px;
  background-image: url(../img/banner/banner_shape.svg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 722px;
  z-index: -1;
}
.banner-area-two .banner-content img {
  margin-bottom: 0;
}
.banner-area-two .banner-content {
  margin-bottom: 0;
}
.banner-area-two .banner-content .title {
  margin-bottom: 65px;
  line-height: 1.36;
  text-transform: none;
}
.banner-social-wrap {
  position: absolute;
  left: 100px;
  bottom: 90px;
}
.banner-social-wrap ul li {
  margin-bottom: 25px;
  border: 1px solid #f3f3f3;
  border-radius: 50px;
  width: 42px;
  display: flex;
  align-items: center;
}
.banner-social-wrap ul li.is-active {
  width: auto;
}
.banner-social-wrap ul li:last-child {
  margin-bottom: 0;
}
.banner-social-wrap ul li a {
  font-size: 15px;
  color: #081a39;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: 0s;
}
.banner-social-wrap ul li span {
  color: #b5bac4;
  font-size: 14px;
  font-weight: 500;
  padding-right: 14px;
  display: none;
}
.banner-scroll {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 40px;
  bottom: 90px;
}
.banner-scroll span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.515em;
  color: #081a39;
  transform: rotate(-90deg);
  margin-bottom: 120px;
  opacity: 0.4;
}

/* 5. Breadcrumb */
.breadcrumb-bg {
  background-image: url(../img/bg/breadcrumb-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 235px 0 150px;
}
.breadcrumb-bg::before {
  position: absolute;
  content: "";
  background-image: url(../img/bg/breadcrumb_shape.png);
  background-repeat: repeat;
  background-position: center;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 19px;
}
.breadcrumb-content {
  text-align: center;
}
.breadcrumb-content .title {
  font-size: 65px;
  margin-bottom: 30px;
  letter-spacing: -0.01em;
  line-height: 1.15;
}
.breadcrumb-content .breadcrumb {
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
}
.breadcrumb-content .breadcrumb .breadcrumb-item {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.27;
  text-decoration: underline;
}
.breadcrumb-content .breadcrumb .breadcrumb-item:first-child {
  text-decoration: none;
}
.breadcrumb-content .breadcrumb .breadcrumb-item a {
  color: #fff;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 15px;
  color: #ffffff;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-size: 17px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  padding-top: 3px;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item {
  padding-left: 15px;
}


/* .countdown-area-two img {
  height: 700px;
} */
/* 6. Countdown */
.countdown-area-two .countdown-wrap {
  /* background: radial-gradient( #20b2a8af,black, black)!important; */
  /* background: black!important; */
  /* border: 3px solid #20B2A9; */
  /* box-shadow: 0px 34px 35px rgba(160, 171, 191, 0.21); */
   backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 20px;
  position: relative;
  text-align: center;
  /* padding: 5px; */
  /* border: 4px solid #20B2A9;
  box-shadow:  0 0 2px #20b2a8; */
  /* margin-bottom:5px; */
  height: auto!important;
  /* width: 100%!important; */
  /* width: 1000px; */
  position: relative;
  object-fit: cover;
  border: 3px solid #20B2A9;

}
.countdown-area-two .countdown-wrap::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  /* width: 80%;
  height: 80%; */
  width: 100%;
  height: 100%;
  /* background: #00090932; */
  opacity: 1;
  border-radius: 20px;
  z-index: -1;
  /* background: url(../img/images/infinaeon.svg); */
  /* background: url(../img/images/dragonr2.gif); */
  /* border: 3px solid #20B2A9; */
  background-repeat: no-repeat;
  background-position: center;
  /* opacity: 0.7; */
  padding-bottom: 20px;
 
 


  
}


.countdown-area-two .countdown-wrap2 {
  background: radial-gradient( #20b2a8af, black)!important;
  /* border: 3px solid #20B2A9; */
  /* box-shadow: 0px 34px 35px rgba(160, 171, 191, 0.21); */
   backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 30px;
  position: relative;
  text-align: center;
  padding: 45px 10px 45px;
  /* border: 4px solid #20B2A9;
  box-shadow:  0 0 2px #20b2a8; */
  margin-bottom:5px;
  /* height: 450px; */
  /* width: 100%!important; */
  width: auto;
  position: relative;
  /* object-fit: cover; */




}

.swiper {
  height: auto!important;

}


.countdown-area-two .countdown-wrap2::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  /* width: 80%;
  height: 80%; */
  width: 100%;
  height: 100%;
  /* background: #00090932; */
  opacity: 1;
  border-radius: 20px;
  z-index: -1;
  /* background: url(../img/images/infinaeon.svg); */
  /* background: url(../img/images/dragonr2.gif); */
  border: 3px solid #20B2A9;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
  /* padding-bottom: 20px; */
  /* height: 450px!important; */

 
 


  
}

.countdown-area-two .countdown-wrap .title {
  color: #ffffff;
  letter-spacing: -0.01em;
  font-size: 16px;
  /* margin-bottom: 35px; */
  line-height: 1.3;
  font-family: ethno;
  font-weight: lighter;
  font-style: italic;
  text-shadow: 0 0 8px rgb(0, 0, 0);
  /* margin-bottom: 50px; */
  /* margin: 0!important; */
}

.mauto {
  margin: auto!important;
}

.cdmain{
  /* padding: 15px; */
  backdrop-filter: none!important;
  -webkit-backdrop-filter: none!important;
  border: none!important;

}

.video-description {
  background: transparent;
  position: absolute;
  top: -45%;
  right: 0;
  bottom: 0;
  left: 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.countdown-area-two .countdown-wrap2 .title {
  color: #ffffff;
  letter-spacing: -0.01em;
  font-size: 20px;
  /* margin-bottom: 35px; */
  line-height: 1.3;
  font-family: ethno;
  font-weight: lighter;
  font-style: italic;
  text-shadow: 0 0 8px rgb(0, 0, 0);
  
}

.countdown-area-two .countdown-wrap .title2 {
  color: #ffffff;
  letter-spacing: -0.01em;
  font-size: 20px;
  /* margin-bottom: 35px; */
  line-height: 1.3;
  font-family: ethno;
  font-weight: lighter;
  font-style: italic;
  text-shadow: 0 0 8px rgb(0, 0, 0);
}
.ClassyCountdown-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;
  column-gap: 115px;
  row-gap: 30px;
}
.ClassyCountdown-wrapper > div > div:not(:last-child) {
  display: block !important;
}
.ClassyCountdown-wrapper > div > div canvas {
  display: block;
  width: 100% !important;
  height: 100% !important;
}
.ClassyCountdown-value {
  display: block;
  line-height: 0;
}
.ClassyCountdown-value > div {
  display: block;
  text-align: center;
  line-height: 1;
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: 600;
  color: #030b15;
  font-family: "Poppins", sans-serif;
  margin-top: -95px;
}
.ClassyCountdown-value > span {
  font-size: 14px;
  display: block;
  text-align: center;
  font-family: "Outfit";
  color: #030b15;
  font-weight: 500;
  line-height: 1;
}

.custom-countdown-two {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-flow: row wrap;
  /* background:  linear-gradient(black,rgba(0, 0, 0, 0.509),  transparent, rgba(0, 0, 0, 0.452),  black), url(../img/images/Infinaeon\ -\ Electric\ Coin.gif); */
  background-size: cover;
  border-radius: 20px;
  background-position: center;

  /* box-shadow: 0 0 10px rgb(1, 255, 221); */

/* height: 600px; */
}


.bgblur {
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
  padding: 10px;
  border-radius: 20px;
}

.custom-countdown-two span {
  text-shadow: 0 2px black;
  /* background: #20b2a897; */
  padding: 10px;
  border-radius: 10px;
   backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  font-size: calc(16px + 0.4vw)!important;
  /* background: linear-gradient(transparent, #20b2a867, #20b2a8); */
  min-width: 80px;
/* background: linear-gradient(rgba(0, 0, 0, 0.854), transparent); */
background: linear-gradient(transparent, black);
  margin-bottom: 5px;
  /* box-shadow: 0 0 10px #20b2a8; */
  /* border: 2px solid #20b2a8; */
  /* box-shadow: 0 2px black; */
 /* border: 2px solid black; */
  /* color: #005e68; */
  /* font-family: ethno; */
  /* font-weight: lighter; */
}
.custom-countdown-two .time-count {
  border-radius: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
  
  /* background: radial-gradient(#20B2A9, rgb(0, 0, 0)); */
  /* font-family: 'Poppins', sans-serif; */
  /* border: 2.5px solid #ddd; */
  font-family: ethno;
  font-weight: lighter;
  text-shadow: 0 2px 4px black;
  font-size: 12px;
  /* margin:20px; */
}
.custom-countdown-two .time-count span {
  /* font-weight: 600; */
  font-size: 30px;
  /* margin:20px; */
}
.custom-countdown-two .time-count.day {
  border-color: #20B2A9;
}
.custom-countdown-two .time-count.hour {
  border-color: #20B2A9;
}
.custom-countdown-two .time-count.min {
  border-color: #20B2A9;
}
.custom-countdown-two .time-count.sec {
  border-color: #20B2A9;
}


.swiper-button-prev {
  /* color: white!important;
 background-color: blue;
 padding: 20px;
 border-radius: 50%; */
}

iframe {
  aspect-ratio: 16 / 9!important;
  width: 100%!important;
}

.inflg {
  width: 60px;
  text-align: center;
  margin-bottom: 20px;
}

/* 7. About */
.about-img {
  position: relative;
  padding-left: 120px;
}
.about-img img.img-two {
  position: absolute;
  left: 60px;
  top: 40px;
}
.about-content {
  /* margin-left: 70px; */
  /* background: linear-gradient( #000000, #000000c2,#000000), url(../../assets/img/images/infvid3.gif)!important; */
  /* background: url(../img//images/infinaeon.svg); */
  background-size: contain!important;
  /* padding: 20px; */
  border-radius: 20px;
  background-repeat: no-repeat!important;
  background-position: center;
  margin: auto;


}

.aboutbr {
  max-width: 1200px;
  margin: auto;
}
.section-title .sub-title {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
}
.section-title .sub-title::after,
.section-title .sub-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #20B2A9;
  border-radius: 50%;
}
.section-title .sub-title::after {
  left: auto;
  right: 0;
}
.section-title .title {
  font-size: 42px;
  margin-bottom: 0;
  letter-spacing: -0.01em;
}
.section-title .title span {
  color: #20B2A9;
}
.about-content p {
  /* margin-bottom: 40px; */
  /* width: 73%; */
  font-size: calc(14px + 0.3vw);
  color: #ffffff;
  text-align: center;

}
.partner-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.partner-wrap ul li {
  width: 20%;
  height: 123px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1px;
  margin-top: -1px;
  cursor: pointer;
}
.partner-wrap ul li img {
  opacity: 0.5;
  transition: 0.3s linear;
}
.partner-wrap ul li:hover img {
  opacity: 1;
}

/* about-two */
.about-area-two {
  padding: 130px 0;
  position: relative;
}
.section-title.section-title-two .sub-title {
  color: #564dca;
  letter-spacing: 0.02em;
  padding: 0;
  text-decoration: underline;
  margin-bottom: 18px;
}
.section-title.section-title-two .sub-title::before,
.section-title.section-title-two .sub-title::after {
  display: none;
}
.section-title.section-title-two .title {
  font-size: 45px;
  color: #030b15;
  line-height: 1.26;
}
.about-content-two p {
  color: #727885;
  margin-bottom: 20px;
  width: 80%;
}
.about-content-two .about-list {
  margin-bottom: 45px;
}
.about-content-two .about-list ul li {
  display: flex;
  align-items: baseline;
  color: #030b15;
  margin-bottom: 10px;
}
.about-content-two .about-list ul li:last-child {
  margin-bottom: 0;
}
.about-content-two .about-list ul li i {
  color: #564dca;
  margin-right: 10px;
}
.about-shape-wrap img {
  position: absolute;
}
.about-shape-wrap img.shape-one {
  left: 9%;
  bottom: 90px;
  animation: leftToRight 5s infinite linear;
}
.about-shape-wrap img.shape-two {
  right: 7%;
  bottom: 22%;
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* 8. Choose */

.tester {
  /* background: linear-gradient(90deg, #141b67, transparent, black)!important; */
  /* background: linear-gradient(90deg, #008c773a, transparent, black)!important; */
  background: linear-gradient(90deg, black, transparent, #008c773a);

}
/* .itemwrap .bgwrap:nth-child(2) {
  background: linear-gradient(90deg, #008c773a, transparent, black);
 

} */

.choose-content {
  /* box-shadow: 0 0 30px #07c0a755; */
  /* box-shadow: 0 0 90px #03bfe982; */
    /* border: 3px solid #03bfe982;  */
    border: 3px solid #20B2A9;

}
.choose-area .row {
  margin: 0 -10px;
}
.choose-area .row [class*="col-"] {
  padding: 0 10px;
}

.presaleimg {
  width: 100%;
}

.choose-item {
 
  /* height: 550px; */
  /* background: #030b15; */
  /* border: 1px solid #1c2a3a; */
  /* border: 3px solid #03bfe9;  */
  border-radius: 15px;
  padding: 20px 30px;

  transition: 0.2s ease;
  /* backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px); */
  /* background-color: rgba(0, 48, 111, 0.035); */
  width: 100%!important;
  /* background: rgba(0, 43, 99, 0.12); */
  /* background: -webkit-linear-gradient(#000000,#000000,#000000,#000000,#000000,#000000,#000000, #00c4ff); */
  /* box-shadow: inset 0 0px 140px rgba(0, 238, 255, 0.193); */
    /* box-shadow: inset 0 0px 140px rgba(0, 238, 255, 0.193); */
  /* box-shadow: inset 0 0px 140px rgba(0, 238, 255, 0.193); */

  /* border: 1px solid rgba(0, 238, 255, 0.433), linear-gradient(rgba(0, 0, 0, 0.516), rgba(0, 0, 0, 0)); */
  /* background: url(../img/images/infwebbg.jpg);
  background-size: contain;
  background-repeat: no-repeat; */
  /* border: 1px solid rgba(0, 101, 126, 0.437); */
  margin-bottom: 10px;
/* max-width: 500px; */
/* height: 650px; */
/* margin: calc(2rem + 0.3vw); */

margin-bottom: 15px;
/* height: calc(400px + 10vw); */

/* background: url(../../assets/img/images/infvid5.gif);
background-size: cover; */
 
}

.choose-item a:hover {
  color: white!important;
}

.choose-item:hover {
  border-color: transparent;
  /* box-shadow:  0 0 30px rgb(0, 77, 220); */
}

/* .iconwrap {
  height: 300px;
  width: 300px;
  float: right;
  margin-top: 50px;
} */
.choose-icon {
  width: 80px;
  height: 80px;
  /* width: calc(30px + 3vw);
  height: calc(30px + 3vw); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.07); */
  border: 3px solid rgba(0, 184, 225, 0.813);
 

  border-radius: 50%;
  /* margin-bottom: 30px; */
  transition: 0.3s ease-in-out;
  padding: 5px;
  /* background: -webkit-linear-gradient(#00ffc7, #00c4ff); */
  /* background: -webkit-linear-gradient(#bafff7, #00c4ff); */
  background: -webkit-linear-gradient(#ffffff, #00c4ff);
  float: right;
  margin: 7px;

}

.choose-area img {
  /* filter: drop-shadow(0 0 20px white)!important; */
}
.choose-item:hover .choose-icon {
  /* box-shadow: 0 0 40px #005eff; */
  /* border: 3px solid rgb(0, 162, 255); */
  /* box-shadow: 0 0 20px #0099ff; */

}
.choose-content .title {
  /* font-size: 16px; */
  /* font-size: calc(16px+1vw)!important; */
  /* margin-bottom: 10px; */
  font-weight: 600;
  text-transform: none;
  line-height: 1.27;

}

.choose-content {
  /* background: -webkit-linear-gradient(#000000, #20b2a868 ); */
 /* background: -webkit-linear-gradient(#00000032,#00000032, #009f9596); */
  border-radius: 20px;
  padding: 25px;
  backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px);
  /* border: 3px solid #00e0d183; */
  /* border: 3px solid #00e0d183; */
}

.choose-content p {
 
  /* font-size: calc(20px - 0.5vw);
  line-height: calc(20px - 0.5vw); */
  /* font-size: 0.8rem; */
  /* line-height: 0.8rem; */
  line-height:  calc(16px + 0.2vw)!important;
  font-size: calc(16px + 0.2vw)!important;
  color: #c5dff8;
  /* margin-bottom: 10px; */
}

.ecotitle {
  font-size: calc(30px + 0.8vw)!important;
  width: 500px;
  margin-left: 35px;
  text-align: justify;
}

.h9 {
  margin-bottom:20px!important;
  font-weight: 300;
  font-size: calc(30px + 0.8vw)!important;
}
.choose-area .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.choose-area .slide-progressbar {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.1);
  /* overflow: hidden;
  background-image: linear-gradient(to right, #20B2A9, #20B2A9);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out; */
  margin-top: 50px;
  position: relative;
}

.choose-area {
  /* background: linear-gradient( #000000, #00000079,#000000),url(../img/images/infwebbg.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.choose-area .slide-progressbar .slide-filler {

  height: 2px;
  background-image: linear-gradient(to right, #20B2A9, #20B2A9);
  background-repeat: no-repeat;
  /* background-size: 0 100%; */
  transition: background-size 0.4s ease-in-out;
}

/* choose-two */
/* .choose-bg {
  background-image: url(../img/bg/choose_bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0 218px;
  position: relative;
}
.choose-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../img/bg/choose_shape01.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 25px;
}
.choose-bg::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url(../img/bg/choose_shape02.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 19px;
} */
/* .choose-item-two {
  margin-bottom: 30px;
}
.choose-icon-two {
  margin-bottom: 35px;
}
.choose-icon-two img {
  height: 48px;
}
.choose-item-two .choose-content {
  width: 77%;
} */

/* 9. Chart */
.chart-inner {
  padding: 0 110px;
}
.chart-bg {
  background-image: url(../img/bg/chart_bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0;
}
.chart-content .nav-tabs {
  border-bottom: none;
  margin: 0 -20px;
  margin-bottom: 50px;
}
.chart-content .nav-tabs .nav-item {
  padding: 0 20px;
}
.chart-content .nav-tabs .nav-link {
  margin-bottom: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  padding: 0;
  position: relative;
}
.chart-content .nav-tabs .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #20B2A9;
  transition: 0.3s linear;
  opacity: 0;
}
.chart-content .nav-tabs .nav-link.active::before {
  opacity: 1;
}
.chart-content {
  width: 72%;
}
.chart-content-inner .title {
  font-size: 55px;
  margin-bottom: 35px;
  letter-spacing: -0.01em;
  line-height: 1.2;
}
.chart-content-inner p {
  color: #a4b4c3;
  margin-bottom: 45px;
}
.chart-content-inner .btn {
  background: #20B2A9;
  min-width: 200px;
  padding: 21px 50px;
}
.chart-content-inner .btn:hover {
  color: #fff;
}
.chart-content-inner .btn::after {
  display: none;
}
.chart-wrap {
  background: #0b1d33;
  border-radius: 20px;
  padding: 60px 45px 60px;
  text-align: center;
}
.chart-wrap img {
  margin-bottom: 75px;
}
.chart-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.chart-wrap ul li {
  width: 50%;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
}
.chart-wrap ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #005f73;
}
.chart-wrap ul li:nth-child(2):before {
  background: #f72585;
}
.chart-wrap ul li:nth-child(3):before {
  background: #5dd400;
}
.chart-wrap ul li:nth-child(4):before {
  background: #ff9700;
}
.chart-wrap ul li:nth-child(5):before {
  background: #20B2A9;
}
.chart-wrap ul li:nth-child(6):before {
  background: #007ff4;
}

/* 10. Counter */
.counter-inner {
  background: #ffffff;
  box-shadow: 0px 34px 35px rgba(160, 171, 191, 0.21);
  border-radius: 20px;
  padding: 64px 75px 33px;
  position: relative;
  margin-top: -120px;
  margin-bottom: 10px;
}
.counter-inner::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 80%;
  height: 80%;
  border-radius: 20px;
  background: #20B2A9;
  opacity: 0.08;
  z-index: -1;
}
.counter-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.counter-icon {
  width: 71px;
  flex: 0 0 71px;
  margin-right: 30px;
}
.counter-content .count {
  display: flex;
  align-items: center;
  color: #030b15;
  letter-spacing: -0.02em;
  font-size: 48px;
  line-height: 0.85;
  margin-bottom: 8px;
}
.counter-content p {
  margin-bottom: 0;
  color: #646580;
  line-height: 1.6;
}

/* 11. RoadMap */
.roadmap-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.roadmap-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(360deg, #0b1d33 0%, rgba(11, 29, 51, 0) 97.51%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  opacity: 0.8;
}
.roadmap-wrap {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1670px;
  overflow-x: auto;
}
.roadmap-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1670px;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.roadmap-item {
  width: 345px;
  display: flex;
  flex-direction: column;
  flex: 0 0 345px;
}
.roadmap-title {
  display: inline-block;
  /* color: #3fe7dc!important; */
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 22px;
  letter-spacing: 0em;
  font-family: ethno!important;
  background: linear-gradient(90deg, #3fe7dc, #187762);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 0 5px rgb(0, 0, 0))!important;
}
.roadmap-content .dot {
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  width: 16px;
  height: 16px;
  background: #20B2A9;
  border-radius: 50%;
}
.roadmap-content .dot::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  background: #20B2A9;
  opacity: 0.1;
  border-radius: 50%;
}
.roadmap-content {
  position: relative;
  margin-left: 57px;
  padding-left: 17px;
  padding-top: 110px;
}
.roadmap-content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #20B2A9;
}
.roadmap-content .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.roadmap-content p {
  margin-bottom: 0;
  color: #a4b4c3;
}
.roadmap-item:nth-child(even) {
  flex-direction: column-reverse;
  margin-bottom: 165px;
  width: 400px;
  flex: 0 0 400px;
}
.roadmap-item:nth-child(even) .roadmap-content .dot {
  top: auto;
  bottom: 0;
}
.roadmap-item:nth-child(even) .roadmap-title {
  margin-top: 22px;
  margin-bottom: 0;
}
.roadmap-item:nth-child(even) .roadmap-content {
  padding-bottom: 110px;
  padding-top: 0;
}
.roadmap-item:not(:first-child) {
  margin-left: -120px;
}
.roadmap-item:nth-child(odd) {
  margin-top: 165px;
  margin-left: -180px;
}
.roadmap-item:nth-child(1) {
  margin-left: 100px;
  width: 288px;
  flex: 0 0 auto;
}
.roadmap-item:last-child {
  width: 290px;
  flex: 0 0 auto;
}
.bt-roadmap-item:nth-child(2) .roadmap-content::before,
.bt-roadmap-item:nth-child(2) .dot,
.bt-roadmap-item:nth-child(2) .dot::before {
  background: #ff4581;
}
.bt-roadmap-item:nth-child(3) .roadmap-content::before,
.bt-roadmap-item:nth-child(3) .dot,
.bt-roadmap-item:nth-child(3) .dot::before {
  background: #ff9700;
}
.bt-roadmap-item:nth-child(4) .roadmap-content::before,
.bt-roadmap-item:nth-child(4) .dot,
.bt-roadmap-item:nth-child(4) .dot::before {
  background: #5dd400;
}
.bt-roadmap-item:nth-child(5) .roadmap-content::before,
.bt-roadmap-item:nth-child(5) .dot,
.bt-roadmap-item:nth-child(5) .dot::before {
  background: #007ff4;
}
.bt-roadmap-item:nth-child(6) .roadmap-content::before,
.bt-roadmap-item:nth-child(6) .dot,
.bt-roadmap-item:nth-child(6) .dot::before {
  background: #ff9700;
}
.bt-roadmap-item:nth-child(7) .roadmap-content::before,
.bt-roadmap-item:nth-child(7) .dot,
.bt-roadmap-item:nth-child(7) .dot::before {
  background: #ff4581;
}
.bt-roadmap-item:nth-child(2) .roadmap-title {
  color: #ff4581;
}
.bt-roadmap-item:nth-child(3) .roadmap-title {
  color: #ff9700;
}
.bt-roadmap-item:nth-child(4) .roadmap-title {
  color: #5dd400;
}
.bt-roadmap-item:nth-child(5) .roadmap-title {
  color: #007ff4;
}
.bt-roadmap-item:nth-child(6) .roadmap-title {
  color: #ff9700;
}
.bt-roadmap-item:nth-child(7) .roadmap-title {
  color: #ff4581;
}

/* roadmap-two */
.roadmap-wrap-two {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 50px;
  backdrop-filter: blur(10px)!important;
-webkit-backdrop-filter: blur(10px)!important;
padding: 20px!important;
border-radius: 20px;
/* background: linear-gradient(black, transparent); */
/* border-bottom: 3px solid #20B2A9; */
}
.roadmap-wrap-two:last-child {
  margin-bottom: 0;
}
.roadmap-wrap-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 8px;
  /* background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%); */
  opacity: 0.1;
}
.roadmap-wrap-two .roadmap-content .title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #030b15;
  line-height: 1.62;
  /* font-family: ethno!important; */
}
.roadmap-wrap-two .roadmap-content p {
  line-height:  calc(14px + 0.2vw)!important;
  font-size: calc(14px + 0.2vw)!important;
  color: #c5dff8;
}
.roadmap-wrap-two .roadmap-content {
  padding-top: 75px;
}
.roadmap-wrap-two .roadmap-item:nth-child(odd) {
  margin-top: 0;
  margin-left: 0;
}
.roadmap-wrap-two .roadmap-item:not(:first-child) {
  margin-left: 0;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-content {
  padding-bottom: 0;
  padding-top: 75px;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) {
  flex-direction: column;
  margin-bottom: 30px;
  flex: 0 0 400px;
  width: 400px;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-content .dot {
  top: 0;
  bottom: auto;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-title {
  margin-top: 0;
  margin-bottom: 22px;
}
.roadmap-wrap-two .roadmap-item:nth-child(2),
.roadmap-wrap-two .roadmap-item:nth-child(1) {
  margin-left: 130px;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) {
  width: 260px;
  flex: 0 0 260px;
}
.roadmap-wrap-two .roadmap-item {
  margin-bottom: 30px;
}
.roadmap-wrap-two .roadmap-item:nth-child(2) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(2) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(2) .dot::before {
  background: #20B2A9;
}
.roadmap-wrap-two .roadmap-item:nth-child(3) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(3) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(3) .dot::before {
  background: #20B2A9;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(4) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(4) .dot::before {
  background: #20B2A9;
}
.roadmap-wrap-two .roadmap-item:nth-child(2) .roadmap-title {
  color: #20B2A9;
}
.roadmap-wrap-two .roadmap-item:nth-child(3) .roadmap-title {
  color: #20B2A9;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) .roadmap-title {
  color: #20B2A9;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .dot::before {
  background: #20B2A9;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .dot::before {
  background: #20B2A9;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .dot::before {
  background: #20B2A9;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .dot::before {
  background: #20B2A9;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .roadmap-title {
  color: #ff4581;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .roadmap-title {
  color: #20B2A9;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .roadmap-title {
  color: #2adace!important;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .roadmap-title {
  color: #20B2A9;
}

/* 12. Document */
/* .area-bg {
  background-image: url(../img/bg/area_bg.png);
  background-size: cover;
  background-position: center;
} */
.document-content .document-list {
  margin-bottom: 40px;
}
.document-content .document-list li {
  font-size: 18px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  padding-left: 15px;
}
.document-content .document-list li:last-child {
  margin-bottom: 0;
}
.document-content .document-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
}

/* 13. Team */
.team-area {
  position: relative;
  z-index: 1;
}
.team-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #0b1d33 0%, rgba(11, 29, 51, 0) 97.51%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}
.team-item {
  text-align: center;
  margin-bottom: 60px;
}
.team-item .team-thumb {
  position: relative;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 30px;
  display: inline-block;
}
.team-item .team-thumb::before {
  content: "";
  left: 0;
  top: 0;
  width: 226px;
  height: 226px;
  background: transparent;
  border: 2px dashed #20B2A9;
  border-radius: 50%;
  position: absolute;
  transition: all 0.3s ease-in-out;
  animation: rotateme 0.4s linear infinite;
  opacity: 0;
}
.team-item:hover .team-thumb::before {
  opacity: 1;
}
.team-item .team-thumb img {
  border-radius: 50%;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}
.team-item:hover .team-thumb img {
  filter: grayscale(0);
}
.team-content .title {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  transition: 0.3s linear;
}
.team-content span {
  display: block;
  margin-bottom: 10px;
}
.team-content .team-social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;
}
.team-content .team-social li {
  padding: 0 10px;
}
.team-content .team-social li a {
  color: #fff;
  font-size: 13px;
}
.team-content .team-social li a:hover {
  color: #20B2A9;
}

/* team-two */
.team-bg {
  background-image: url(../img/bg/team_bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0 100px;
  position: relative;
}
.team-bg::after,
.team-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../img/bg/team_shape01.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 29px;
}
.team-bg::after {
  background-image: url(../img/bg/team_shape02.png);
  top: auto;
  bottom: 0;
  height: 23px;
}
.team-item.team-item-two .team-thumb img {
  border-radius: 0;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}
.team-item.team-item-two:hover .team-thumb img {
  filter: grayscale(0);
}
.team-item.team-item-two .team-thumb {
  border-radius: 0;
  padding: 0;
  margin-bottom: 35px;
}
.team-item.team-item-two .team-thumb::before {
  display: none;
}
.team-item.team-item-two {
  margin-bottom: 30px;
}

/* 14. Download */
.download-content p {
  color: #727885;
  margin-bottom: 30px;
}
.download-btn {
  display: flex;
  align-items: center;
}
.download-btn a {
  margin-right: 20px;
  margin-top: 10px;
  display: block;
}

/* 15. Faq */
.faq-area {
  background: #f3f6fc;
  padding: 120px 0 130px;
  position: relative;
  z-index: 1;
}
.faq-wrap .accordion-item {
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
  position: relative;
}
.faq-wrap .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-wrap .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.faq-wrap .accordion-item:first-of-type .accordion-button:not(.collapsed) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.faq-wrap .accordion-button {
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 1.25;
  padding: 37px 100px 37px 55px;
  border-radius: 100px;
  position: relative;
}
.faq-wrap .accordion-button:not(.collapsed) {
  color: #030b15;
  background-color: #fff;
  box-shadow: none;
  border-radius: 0;
}
.faq-wrap .accordion-body {
  padding: 0 40px 45px 55px;
  background: #fff;
  position: relative;
}
.faq-wrap .accordion-body::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #20B2A9;
}
.faq-wrap .accordion-body p {
  margin-bottom: 0;
  color: #727885;
  font-size: 18px;
  line-height: 1.55;
}
.faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
.faq-wrap .accordion-button::after {
  width: auto;
  height: auto;
  content: "\f063";
  background-image: none;
  background-size: auto;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 25px;
  position: absolute;
  right: 50px;
  top: 36px;
  color: #132047;
  opacity: 0.3;
}
.faq-wrap .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(-180deg);
  opacity: 1;
}
.faq-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.faq-shape-wrap img.img-one {
  left: 7%;
  bottom: 15%;
  animation: leftToRight 5s infinite linear;
}
.faq-shape-wrap img.img-two {
  right: 6%;
  top: 15%;
}
.faq-shape-wrap img.img-three {
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
}

/* 16. Blog */
.blog-post-item {
  margin-bottom: 60px;
}
.blog-post-thumb {
  margin-bottom: 30px;
}
.blog-post-thumb img {
  border-radius: 15px;
  width: 100%;
}
.blog-meta ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.blog-meta ul li {
  color: #030b15;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-right: 40px;
  margin-top: 5px;
}
.blog-meta ul li:last-child {
  margin-right: 0;
}
.blog-meta ul li i {
  margin-right: 10px;
  color: #564dca;
}
.blog-meta ul li a {
  color: #030b15;
}
.blog-meta ul li a:hover {
  color: #564dca;
}
.blog-post-content .title {
  color: #030b15;
  letter-spacing: -0.02em;
  font-size: 36px;
  margin-bottom: 20px;
  line-height: 1.27;
}
.blog-post-content .title a:hover {
  color: #564dca;
}
.blog-post-content p {
  margin-bottom: 30px;
}
.blog-post-content .btn.btn-two i {
  margin-left: 20px;
}
.blog-sidebar {
  margin-left: 25px;
}
.blog-widget {
  border: 1px solid #f2f5fa;
  box-shadow: 0px 12px 10px rgba(238, 239, 240, 0.25);
  background: #fff;
  padding: 40px 30px;
  margin-bottom: 40px;
}
.blog-widget .bw-title {
  font-size: 22px;
  margin-bottom: 20px;
  color: #030b15;
  letter-spacing: -0.02em;
}
.blog-widget .sidebar-search {
  position: relative;
}
.blog-widget .sidebar-search input {
  background: #f3f6fc;
  border: 1px solid #f2f4f6;
  color: #030a39;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 19px 50px 19px 20px;
  height: 60px;
}
.blog-widget .sidebar-search input::placeholder {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #727885;
}
.blog-widget .sidebar-search button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 14px;
  color: #030a39;
}
.blog-widget .category-list ul li {
  margin-bottom: 15px;
}
.blog-widget .category-list ul li:last-child {
  margin-bottom: 0;
}
.blog-widget .category-list ul li a {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #727885;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #f2f5fa;
  background: #ffffff;
  padding: 11px 18px;
}
.blog-widget .category-list ul li a span {
  margin-left: auto;
}
.blog-widget .category-list ul li a:hover {
  color: #171717;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
}
.blog-widget .page-list ul li {
  margin-bottom: 20px;
}
.blog-widget .page-list ul li:last-child {
  margin-bottom: 0;
}
.blog-widget .page-list ul li a {
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #727885;
}
.blog-widget .page-list ul li a i {
  margin-left: auto;
}
.blog-widget .page-list ul li a:hover {
  color: #171717;
}
.rc-post-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.rc-post-item:last-child {
  margin-bottom: 0;
}
.rc-post-thumb {
  width: 97px;
  flex: 0 0 97px;
  margin-right: 25px;
}
.rc-post-thumb img {
  border-radius: 10px;
}
.rc-post-content span {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #727885;
  display: block;
  margin-bottom: 5px;
}
.rc-post-content .title {
  font-size: 15px;
  color: #141515;
  margin-bottom: 0;
  line-height: 1.42;
}
.rc-post-content .title a:hover {
  color: #564dca;
}
.blog-widget .tag-list ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
}
.blog-widget .tag-list ul li {
  padding: 0 5px 10px;
}
.blog-widget .tag-list ul li a {
  color: #b6b7ba;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #f2f4f6;
  padding: 6px 20px;
  display: block;
}
.blog-widget .tag-list ul li a:hover {
  opacity: 1;
  color: #fff;
  background: #564dca;
  border-color: #564dca;
}
.blog-widget:last-child {
  margin: 0 0;
}

/* blog-details */
.blog-details-wrap .blog-post-content p {
  margin-bottom: 15px;
}
.bd-approach-wrap {
  margin: 50px 0 65px;
}
.bd-approach-wrap .row .col-46 {
  width: 46%;
  flex: 0 0 46%;
}
.bd-approach-wrap .row .col-54 {
  width: 54%;
  flex: 0 0 54%;
}
.bd-approach-content {
  width: 93%;
}
.bd-approach-content .title {
  font-size: 24px;
  margin-bottom: 20px;
}
.bd-approach-content ul li {
  font-weight: 500;
  font-size: 15px;
  color: #171151;
  margin-bottom: 5px;
}
.bd-approach-content ul li:last-child {
  margin-bottom: 0;
}
.bd-approach-content ul li i {
  color: #564dca;
  margin-right: 15px;
}
.bd-approach-img img {
  border-radius: 10px;
}
.bd-technology-content .title {
  letter-spacing: -0.03em;
  font-size: 24px;
  margin-bottom: 25px;
}
.blog-details-img {
  margin: 35px 0 40px;
}
.blog-details-img img {
  border-radius: 10px;
  margin-bottom: 20px;
}
.blog-details-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 36px 0;
  border-top: 1px solid #eceef3;
  border-bottom: 1px solid #eceef3;
}
.blog-details-tags {
  width: 70%;
  flex: 0 0 70%;
}
.blog-details-tags ul {
  display: flex;
  align-items: center;
}
.blog-details-tags ul li {
  margin-top: 5px;
  margin-bottom: 5px;
}
.blog-details-social ul li.social-title,
.blog-details-tags ul li.tag-title {
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins";
  letter-spacing: -0.02em;
  color: #030b15;
  margin-right: 20px;
}
.blog-details-tags ul li a {
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #c1c1c1;
  display: block;
  padding: 5px 18px;
}
.blog-details-tags ul li a:hover {
  background: #564dca;
  color: #fff;
}
.blog-details-social {
  width: 30%;
  flex: 0 0 30%;
}
.blog-details-social ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.blog-details-social ul li {
  margin-right: 20px;
}
.blog-details-social ul li:last-child {
  margin-right: 0;
}
.blog-details-social ul li a {
  font-size: 15px;
  color: #0072ac;
}
.blog-details-social ul li:nth-child(3) a {
  color: #da0021;
}
.blog-details-social ul li:nth-child(4) a {
  color: #0072ac;
}
.blog-details-social ul li:nth-child(5) a {
  color: #3ac4ff;
}
.comment-wrap {
  border-bottom: 1px solid #eceef3;
}
.comment-wrap .title {
  font-size: 20px;
  color: #030b15;
  margin: 0 0 30px;
}
.latest-comments ul li .comments-box {
  display: flex;
  align-items: flex-start;
  width: 74%;
  margin-bottom: 60px;
}
.latest-comments ul li .comments-box .comments-avatar {
  width: 80px;
  flex: 0 0 80px;
  margin-right: 30px;
}
.latest-comments ul li .comments-box .comments-avatar img {
  border-radius: 50%;
}
.latest-comments ul li .comments-box .avatar-name {
  margin-bottom: 10px;
}
.latest-comments ul li .comments-box .avatar-name .title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: #030b15;
  font-size: 16px;
  margin-bottom: 0;
}
.latest-comments ul li .comments-box .avatar-name .title a {
  font-size: 20px;
  opacity: 0.5;
}
.latest-comments ul li .comments-box .avatar-name .title a:hover {
  opacity: 1;
}
.latest-comments ul li .comments-box .avatar-name span {
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  color: #727885;
}
.latest-comments ul li .comments-box .comment-text p {
  margin-bottom: 0;
  font-size: 14px;
}
.latest-comments ul li .children {
  margin-left: 100px;
}
.latest-comments ul li .children .comments-box {
  width: 84%;
}
.post-comments-form {
  margin-top: 50px;
}
.post-comment-content {
  margin-bottom: 45px;
}
.post-comment-content .title {
  color: #030b15;
  margin-bottom: 10px;
  letter-spacing: -0.02em;
  font-size: 20px;
}
.post-comment-content p {
  margin-bottom: 0;
}
.comment-form .form-grp {
  margin-bottom: 30px;
}
.comment-form .form-grp textarea,
.comment-form .form-grp input {
  display: block;
  border: none;
  width: 100%;
  background: #f3f6fc;
  border-radius: 5px;
  color: #171151;
  font-weight: 400;
  font-size: 15px;
  display: block;
  padding: 17px 20px;
  height: 60px;
}
.comment-form .form-grp textarea::placeholder,
.comment-form .form-grp input::placeholder {
  color: #b2b0c1;
  font-weight: 400;
  font-size: 15px;
}
.comment-form .form-grp textarea {
  min-height: 172px;
  max-height: 172px;
}
.comment-form .btn.btn-two:hover {
  background: #20B2A9;
}

/* 17. Pagination */
.pagination-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 0 !important;
}
.pagination-wrap ul li {
  display: block;
  margin: 10px 3.7px 0;
}
.pagination-wrap ul li a {
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f2f5fa;
  border-radius: 5px;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  color: #8a879f;
}
.pagination-wrap ul li a:hover,
.pagination-wrap ul li .current {
  color: #fff;
  background: #564dca;
  border-color: #564dca;
}

/* 18. Contact */
.contact-info-wrap {
  margin-bottom: 50px;
}
.contact-info-item {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.contact-info-item::before {
  content: "";
  position: absolute;
  right: -61px;
  top: 44px;
  background-image: url(../img/images/line.png);
  width: 122px;
  height: 9px;
}
.contact-info-wrap .row [class*="col-"]:last-child .contact-info-item::before {
  display: none;
}
.contact-info-item .icon {
  width: 107px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  font-size: 28px;
  color: #ff9700;
  margin: 0 auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.07);
  z-index: 1;
  margin-bottom: 10px;
}
.contact-info-item .icon-background {
  position: absolute;
  width: 83px;
  height: 83px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0b1d33;
  border-radius: 50%;
  z-index: -1;
}
.contact-info-item .icon-background::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 83px;
  height: 83px;
  background: transparent;
  border: 3px solid #ff9700;
}
.contact-info-item .content p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 30px;
}
.contact-info-wrap .row [class*="col-"]:nth-child(2) .icon {
  color: #ff4581;
}
.contact-info-wrap .row [class*="col-"]:nth-child(3) .icon {
  color: #20B2A9;
}
.contact-info-wrap .row [class*="col-"]:nth-child(2) .icon-background::before {
  border-color: #ff4581;
}
.contact-info-wrap .row [class*="col-"]:nth-child(3) .icon-background::before {
  border-color: #20B2A9;
}
.contact-form-wrap {
  background-image: url(../img/bg/contact_bg.png);
  background-position: center;
  background-size: cover;
  padding: 80px;
}
.contact-form-wrap .form-grp {
  margin-bottom: 30px;
}
.contact-form-wrap .form-grp textarea,
.contact-form-wrap .form-grp input {
  width: 100%;
  background: #0b1d33;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  padding: 18px 20px;
  height: 65px;
}
.contact-form-wrap .form-grp textarea::placeholder,
.contact-form-wrap .form-grp input::placeholder {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  opacity: 0.7;
}
.contact-form-wrap .form-grp textarea {
  min-height: 184px;
  max-height: 184px;
}
.contact-form-wrap .submit-btn .btn {
  background: #0b1d33;
  border-color: #20B2A9;
}

/* contact-two */
.contact-bg {
  background-image: url(../img/bg/contact_bg02.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0 250px;
  position: relative;
}
.contact-bg::after,
.contact-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../img/bg/contact_shape01.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 29px;
}
.contact-bg::after {
  background-image: url(../img/bg/contact_shape02.png);
  top: auto;
  bottom: 0;
  height: 18px;
}
.contact-inner {
  padding: 0 40px;
}
.contact-info-wrap-two .title {
  font-size: 50px;
  margin-bottom: 35px;
  letter-spacing: -0.03em;
  line-height: 1.2;
}
.contact-list-item {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  display: flex;
  align-items: center;
  padding: 22px 32px;
  width: 69%;
  margin-bottom: 15px;
}
.contact-list-item:last-child {
  margin-bottom: 0;
}
.contact-list-item .icon {
  width: 35px;
  flex: 0 0 35px;
  margin-right: 22px;
}
.contact-list-item .content p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 146.49%;
  color: #ffffff;
}
.contact-form-wrap-two .title {
  font-size: 30px;
  text-transform: none;
  letter-spacing: -0.03em;
  line-height: 2;
  margin-bottom: 35px;
}
.contact-form-wrap-two textarea,
.contact-form-wrap-two input {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  width: 100%;
  letter-spacing: -0.02em;
  color: #030b15;
  font-weight: 400;
  font-size: 20px;
  padding: 17px 22px;
  line-height: 1.5;
  height: 66px;
  margin-bottom: 20px;
  display: block;
  transition: 0.3s linear;
}
.contact-form-wrap-two textarea::placeholder,
.contact-form-wrap-two input::placeholder {
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
}
.contact-form-wrap-two textarea:focus,
.contact-form-wrap-two input:focus {
  background: #fff;
  border-color: #fff;
}
.contact-form-wrap-two textarea {
  min-height: 165px;
  max-height: 165px;
}
.contact-form-wrap-two .btn.btn-two {
  background: #20B2A9;
}
.contact-form-wrap-two .btn.btn-two:hover {
  background: #564dca;
}

/* 19. Newsletter */
.newsletter-wrap {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 26px 23px rgba(81, 98, 132, 0.06);
  border-radius: 10px;
  padding: 64px 50px;
  margin-top: -120px;
  z-index: 1;
  position: relative;
}
.newsletter-content {
  width: 42.5%;
  flex: 0 0 42.5%;
}
.newsletter-content .title {
  color: #030b15;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 0;
  letter-spacing: -1px;
}
.newsletter-form {
  width: 57.5%;
  flex: 0 0 57.5%;
}
.newsletter-form form {
  position: relative;
}
.newsletter-form input {
  width: 100%;
  border: 1px solid #eceef3;
  border-radius: 4px;
  background: #f3f6fc;
  font-size: 16px;
  font-weight: 400;
  color: #030b15;
  padding: 26px 215px 26px 25px;
  line-height: 1.25;
  height: 72px;
}
.newsletter-form input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #667279;
}
.newsletter-form form button {
  position: absolute;
  right: 12px;
  top: 8px;
  bottom: 8px;
}
.newsletter-form .btn.btn-two {
  padding: 21px 47px;
}

/* 20. Footer */
.footer-area {
  position: relative;
  z-index: 1;
}
/*footerbg*/


.footer-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    359.43deg,
    #0b1d33 11.06%,
    rgba(11, 29, 51, 0) 99.43%
  ); */

  /* background: linear-gradient(
    359.43deg,
    #004e66 1.06%,
    rgba(11, 29, 51, 0) 50.43%
  ); */
    /* background: linear-gradient(
    359.43deg,
    #004e66 1.06%,
    rgba(11, 29, 51, 0) 50.43%
  ); */

  /* background: linear-gradient(
    359.43deg,
    #004e66 2.06%,
    #007d86 2.06%,
    rgba(11, 29, 51, 0) 50.43%
  ); */
  /* background: linear-gradient( #000000 30.05%,#009a88 50%,#0032b2 60%, #4388dd 90.28%); */
  /* background:  linear-gradient( #000000 60%,#009a8841 92%); */
  /* background-size: cover; */
    /* background:  linear-gradient( #000000 20%,rgba(0, 238, 255, 0.424) 92%); */
    /* background: -webkit-linear-gradient( #000d0f 2%,#000d0f, #000d0f, #000d0f, #000d0f, #004e66); */


  z-index: -1;
}
.footer-scroll-wrap {
  position: relative;
  z-index: 1;
}
.footer-scroll-wrap .scroll-to-target {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
background:black;
  border: 3px solid #20b2a88b;
  border-radius: 50%;
  font-size: 18px;
  padding: 0 0;
  color: #fff;
  margin: 0 auto;
}
.footer-scroll-wrap .scroll-to-target:hover {
  background: linear-gradient(black, #20b2a945);
  border-color: #20B2A9;
}
.footer-scroll-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
background: rgba(0, 160, 184, 0.209)!important;

  z-index: -1;
}
.footer-top {
  padding: 55px 0 50px;
}
.footer-widget {
  margin-bottom: 30px;
}
.footer-widget .f-logo {
  margin-bottom: 20px;
  display: block;
  width: 100px;
}
.footer-content p {
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 26px;
  color: #c5dff8;

}
.footer-content .footer-social {
  display: flex;
  align-items: center;
  margin: 0 -7px;
}
.footer-content .footer-social li {
  padding: 0 7px;
}
.footer-content .footer-social li a {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  /* border: 2px solid #00716f7e; */
  border: 1px solid rgb(0, 101, 126);
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}
.footer-content .footer-social li a:hover {
  background: #20B2A9;
  border-color: #000000;
  color: black;
}
.footer-widget .fw-title {
  font-size: 17px;
  letter-spacing: 0em;
  text-transform: uppercase;
  margin: 25px 0 27px;
  text-align: left!important;
  /* font-family: ethno;
  font-weight: 100; */
}
.footer-link ul li {
  margin-bottom: 10px;

}
.footer-link ul li:last-child {
  margin-bottom: 0;
}
.footer-link ul li a {
  font-size: 15px;
  line-height: 20px;
  color: #c5dff8;
  position: relative;

  
}
.footer-link ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link ul li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-link ul li a:hover {
  color: #fff;
}
.footer-top .row [class*="col-"]:nth-child(2) .footer-widget {
  margin-left: 80px;
}
.footer-top .row [class*="col-"]:nth-child(4) .footer-widget {
  margin-left: 75px;
}
.footer-newsletter p {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 60px;
  color: #a4b4c3;
}
.footer-newsletter form {
  position: relative;
}
.footer-newsletter form input {
  width: 100%;
  border: none;
  background: rgb(118 118 130 / 10%);
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  padding: 23px 80px 23px 25px;
  color: #fff;
  height: 72px;
}
.footer-newsletter form input::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}
.footer-newsletter form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 65px;
  height: 72px;
  background: #20B2A9;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  border: none;
}
.footer-bottom {
  /* border-top: 1px solid rgb(255 255 255 / 6%); */
  padding: 27px 0;
}
.copyright-text p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
}
.footer-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -30px;
}
.footer-menu ul li {
  padding: 0 30px;
}
.footer-menu ul li a {
  font-size: 15px;
  color: #a4b4c3;
  font-weight: 500;
}
.footer-menu ul li a:hover {
  color: #fff;
}

/* footer-two */
.footer-area-two .footer-top {
  padding: 70px 40px 65px;
}
.footer-menu-two .navigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer-menu-two .navigation li {
  margin-right: 60px;
}
.footer-menu-two .navigation li:last-child {
  margin-right: 0;
}
.footer-menu-two .navigation li a {
  color: #030b15;
  font-weight: 500;
  font-size: 16px;
}
.footer-menu-two .navigation li a:hover {
  color: #20B2A9;
}
.footer-area-two .footer-social ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-area-two .footer-social ul li {
  margin-right: 40px;
}
.footer-area-two .footer-social ul li:last-child {
  margin-right: 0;
}
.footer-area-two .footer-social ul li a {
  color: #0a142f;
  opacity: 0.3;
  font-size: 24px;
  line-height: 1;
}
.footer-area-two .footer-social ul li a:hover {
  color: #20B2A9;
  opacity: 1;
}
.footer-area-two .footer-bottom {
  border-top: 1px solid #f0f3f9;
  padding: 40px 40px;
}
.footer-area-two .copyright-text p {
  color: #282f3b;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.footer-area-two .scroll-up .scroll-to-target {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #000000;
  padding: 0 0;
  border: none;
  color: #fff;
  font-size: 17px;
  margin: -60px auto 15px;
}
.footer-area-two .scroll-up .scroll-to-target:hover {
  background: linear-gradient(black,#20B2A9)!important;

}
.footer-area-two .scroll-up span {
  display: block;
  color: #0a142f;
  font-size: 14px;
  font-weight: 500;
}
.footer-bottom-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-bottom-menu ul li {
  margin-right: 30px;
}
.footer-bottom-menu ul li:last-child {
  margin-right: 0;
}
.footer-bottom-menu ul li a {
  font-size: 15px;
  color: #0a142f;
  font-weight: 500;
}
.footer-bottom-menu ul li a:hover {
  color: #20B2A9;
}

/* footer-three */
.footer-bg {
  background-image: url(../img/bg/breadcrumb-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 120px;
}
.footer-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../img/bg/footer_shape.png);
  background-repeat: repeat;
  background-position: center;
  width: 100%;
  height: 29px;
}
.footer-area-three .footer-top {
  padding: 0 0 50px;
}

.bt-roadmap-wrap {
  display: flex;
  padding: 0;
  min-height: 490px;
  margin: 10px 0 0;
  justify-content: flex-start;
}
.bt-roadmap_x {
  position: relative;
}
.bt-roadmap_x::before {
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 0px);
  transform: translateY(-50%);
  width: 1670px;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.bt-roadmap-item {
  display: flex;
  width: 370px;
  flex: 0 0 auto;
  height: 305px;
  align-self: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.bt-roadmap-item:nth-child(even) {
  align-self: flex-start;
  flex-direction: column-reverse;
  margin-top: -10px;
}
.bt-roadmap-item:nth-child(even) .roadmap-title {
  margin: 22px 0 0;
}
.roadmap-content span {
  display: block;
  color: #a4b4c3;
}
.bt-roadmap-item:nth-child(even) .roadmap-content {
  padding: 0 0 110px 17px;
}
.bt-roadmap-item:nth-child(even) .roadmap-content .dot {
  top: auto;
  bottom: 0;
}
.bt-roadmap-item:not(:first-child) {
  margin-left: -155px;
}
.bt-roadmap-item:last-child {
  width: 280px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  display: none;
}
.bt-roadmap_x._mCS_1.mCS_no_scrollbar {
  padding-bottom: 0;
}
.bt-roadmap_x.mCustomScrollbar {
  padding: 0 0 30px;
}
.mCustomScrollBox
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
}
.bt-roadmap_x:not(.mCS_no_scrollbar):before {
  top: calc(50% - 15px);
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #00c2f2;
}

.bt-roadmap-scrollbar::-webkit-scrollbar {
  width: 4px !important;
}
.bt-roadmap-scrollbar::-webkit-scrollbar-track {
  background: transparent; 
}
.bt-roadmap-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.75); 
}
.bt-roadmap-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #ff0; 
}

.buynowbtn {
  /* width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 200px!important;
  margin: auto!important; */
  width: 100%;
  /* margin-left: 10%; */
  /* background: transparent; */
  background: #20b2a8!important;
  border: none;
  margin: auto;
}


.buynowbtn a {
  
  text-shadow: 0 0 4px black;
/* font-size: calc(20px + 1.2vw); */
/* font-size: 20px; */
/* font-size: 50px; */
/* font-size: calc(10px + 1.1vw);
padding: calc(5px + .5vw); */
padding: 10px;
/* font-family: ethno; */
/* color: rgb(0, 55, 255); */
color: rgb(255, 255, 255);
/* line-height: calc(16px + 3vw); */
font-size: calc(20px + 0.5vw);
font-family: ethno;
font-weight: lighter;
font-style: italic;

}

.mintnftbtn {
  width: 100%;
  /* margin-right: 10%; */
  margin: auto;
  background: transparent;
  border: none;
  margin: auto;
 
}

.mintnftbtn a {
  padding: 10px;
  /* font-family: ethno; */
  /* color: rgb(0, 55, 255); */
  color: rgb(255, 255, 255);
  /* line-height: calc(16px + 3vw); */
  text-shadow: 0 0 4px black;
  font-size: calc(20px + 0.5vw);
  font-family: ethno;
  font-weight: lighter;
 font-style: italic;
}


.btngroup2 {
  /* max-width: 1175px!important; */
  /* margin: auto!important;
  margin-left: 2%;
  margin-right: 2%; */
}

.firstcol {
  /* width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 200px!important;
  margin: auto!important; */
  /* width: 100%; */

  
  margin: auto!important;
  border-radius: 15px;
  /* background: -webkit-linear-gradient(#00d9ed 30%, #00c3ff, rgb(0, 55, 255)); */
  /* box-shadow: 0 0 20px rgb(0, 140, 255); */
  /* border: none; */
  margin-top: 30px;
  /* border: 3px solid #00c3ff; */
  /* background: linear-gradient(black ,black) padding-box,
linear-gradient(to right, rgb(0, 60, 255), rgb(0, 225, 237), rgb(0, 119, 255)) border-box; */
/* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0) ) padding-box,
linear-gradient(to right, #00f295, rgb(28, 188, 166), rgb(66, 232, 244), #00eeff) border-box; */
/* background: linear-gradient(rgb(0, 0, 0) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #009159, rgb(30, 210, 183), rgb(40, 189, 200), #009159) border-box;
border: 2px solid transparent; */
backdrop-filter: blur(20px)!important;
-webkit-backdrop-filter: blur(20px)!important;
/* background:  #00f7ff60; */
background: transparent;
/* box-shadow: 0 0 20px #00f7ff; */
/* border: 3px solid rgb(0, 157, 255);
background:rgb(0, 212, 181); */
/* border: 2px solid rgba(255, 255, 255, 0.441); */
border: 3px solid #00f7ff8b;
}

.secondcol {
  /* width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 200px!important;
  margin: auto!important; */
  /* width: 100%; */

  
  margin: auto!important;
  border-radius: 15px;
  /* background: -webkit-linear-gradient(#00d9ed 30%, #00c3ff, rgb(0, 55, 255)); */
  /* box-shadow: 0 0 20px rgb(0, 140, 255); */
  /* border: none; */
  margin-top: 30px;
  /* border: 3px solid #00c3ff; */
  /* background: linear-gradient(black ,black) padding-box,
linear-gradient(to right, rgb(0, 60, 255), rgb(0, 225, 237), rgb(0, 119, 255)) border-box; */
/* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0) ) padding-box,
linear-gradient(to right, #00f295, rgb(28, 188, 166), rgb(66, 232, 244), #00eeff) border-box; */
/* background: linear-gradient(rgb(0, 0, 0) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #009159, rgb(30, 210, 183), rgb(40, 189, 200), #009159) border-box;
border: 2px solid transparent; */
backdrop-filter: blur(20px)!important;
-webkit-backdrop-filter: blur(20px)!important;
/* background:  #00f7ff60; */
background: transparent;
/* box-shadow: 0 0 20px #00f7ff; */
/* border: 3px solid rgb(0, 157, 255);
background:rgb(0, 212, 181); */
/* border: 2px solid rgba(255, 255, 255, 0.441); */
border: 3px solid #00f7ff8b;
}


.mint  {
  border-radius: 15px;
  /* background: -webkit-linear-gradient(#00d9ed 30%, #00c3ff, rgb(0, 55, 255)); */
  /* box-shadow: 0 0 20px rgb(0, 140, 255); */
  /* border: none; */
  margin-top: 30px;
  /* border: 3px solid #00c3ff; */
  /* background: linear-gradient(black ,black) padding-box,
linear-gradient(to right, rgb(0, 60, 255), rgb(0, 225, 237), rgb(0, 119, 255)) border-box; */
/* background: linear-gradient(rgb(0, 112, 95), rgb(0, 0, 0) ) padding-box,
linear-gradient(to right, #00f295, rgb(28, 188, 166), rgb(66, 232, 244), #00eeff) border-box; */
/* background: linear-gradient(rgb(0, 0, 0) ,rgb(0, 112, 95)) padding-box,
linear-gradient(to right, #009159, rgb(30, 210, 183), rgb(40, 189, 200), #009159) border-box;
border: 2px solid transparent; */
backdrop-filter: blur(20px)!important;
-webkit-backdrop-filter: blur(20px)!important;
/* background:  #00f7ff60; */
background: transparent;
/* box-shadow: 0 0 20px #00f7ff; */
/* border: 3px solid rgb(0, 157, 255);
background:rgb(0, 212, 181); */
/* border: 2px solid rgba(255, 255, 255, 0.441); */
border: 3px solid #00f7ff8b;
/* display: inline-block!important; */
/* font-family: ethno; */
/* font-weight: lighter; */
}

.mint a {
  /* color: rgb(0, 0, 0); */
  /* text-shadow: 0 0 7px black; */
  /* font-size: calc(7px + 1.2vw); */
  /* font-size: 18px; */
  /* font-size: 40px; */
  /* padding: 5px!important; */
  /* padding: calc(3px + .5vw); */
  padding: 10px;
  /* font-family: ethno; */
  /* color: rgb(0, 55, 255); */
  color: rgb(255, 255, 255);
  /* line-height: calc(16px + 3vw); */
  text-shadow: 0 0 4px black;
  font-size: calc(20px + 1vw);
  font-family: ethno;
  font-weight: lighter;
 font-style: italic;
}


.button-container {
  display: flex;
  justify-content: space-between;
}

.pulsate-bck{-webkit-animation:pulsate-bck 2.5s ease-in-out infinite both;animation:pulsate-bck 2.5s ease-in-out infinite both}



 @-webkit-keyframes pulsate-bck{0%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(.9);transform:scale(.9)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes pulsate-bck{0%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(.9);transform:scale(.9)}100%{-webkit-transform:scale(1);transform:scale(1)}}


 h6 {
  text-shadow: 0 2px 4px black!important;
  /* background: #20b2a877; */
  font-size: calc(13px + .2vw)!important;
 /* padding-top: 10px;
 padding-bottom: 10px; */
 backdrop-filter: blur(5px)!important;
-webkit-backdrop-filter: blur(5px)!important;
/* border-radius: 20px; */
/* box-shadow: 0 0 30px black; */
padding: 5px;
  
 }

 